import BootstrapTable from 'react-bootstrap-table-next';
//import Card from 'react-bootstrap/Card';re
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import qadata from "./Phy1mark.json";
import React, { Component, useState } from "react";
import { Form, Button } from "react-bootstrap";
import logo from './Answer_t.png'
import { Row, Col, Container } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
//import TypeWriter from "./typewriter"
import { Typewriter } from 'react-simple-typewriter';
import { Carousel } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card';
//import logos1 from './Physics/12/Unit2/Slide2.jpg'
import { MathJax, MathJaxContext } from "better-react-mathjax";




import { AiOutlineAudioMuted, AiOutlineAudio, AiOutlineFileSearch } from 'react-icons/ai';

//import logo3 from { slide }
import paginationFactory from 'react-bootstrap-table2-paginator';



var kk = "dummy.png"

var selectcolor = "red"

var tex_visible = 'visible';

var img_visible = 'visible';

var logo1 = "";

var ques = "";
var answ = "";
var answ5r = "";
var answr = "";
answr = "Please select any question and click answer button"

//logo1 = require('./Physics/12/Unit2/'+kk);  --- Working code

logo1 = require('./VK.PNG'); 


const { SearchBar, ClearSearchButton  } = Search;

var typestring = "";

typestring = ""

var moshow = "false"
var logos = "";



const columns = [
    {
        dataField: 'class',
        text: 'Class Name',
        hidden: true

    },
    {
        dataField: 'Subject',
        text: 'Subject Name',
        hidden: true


    }, {
        dataField: 'chapter',
        text: 'Chapter',
         },
    {
        dataField: 'question',
        text: 'Question',
        formatter: priceFormatter

    },
    {
        dataField: 'answer1',
        text: 'Answer1',
        hidden: true

    },
    {
        dataField: 'answer2',
        text: 'Answer2',
        hidden: true

    },
    {
        dataField: 'answer3',
        text: 'Answer3',
        hidden: true

    },
    {
        dataField: 'answer4',
        text: 'Answer4',
        hidden: true

    },
    {
        dataField: 'answer5',
        text: 'Answer5',
        hidden: true

    },
    {
        dataField: 'questionr',
        text: 'questionr',
        hidden: true

    },
    {
        dataField: 'answer1r',
        text: 'Answer1r',
        hidden: true

    },
    {
        dataField: 'answer2r',
        text: 'Answer2r',
        hidden: true

    },
    {
        dataField: 'answer3r',
        text: 'Answer3r',
        hidden: true

    },
    {
        dataField: 'answer4r',
        text: 'Answer4r',
        hidden: true

    },
    {
        dataField: 'answer5r',
        text: 'Answer5r',
        hidden: true

    },
    {
        dataField: 'slide',
        text: 'slide',
        hidden: true

    }
    /*
    
    {
        dataField: 'Read',
        text: '',
        formatter: (cellContent, row) => (
            <div className="Read1">
                <Button
                    className="btnFormSend"
                    variant="success"
                    onClick={() => newmyfun1()}>
                    🔊
                         </Button>

                <Button
                    className="btnFormSend"
                    variant="danger"
                    onClick={() => mycancel()}>
                    ⏹
                        </Button>

            </div>
        )
    },
    */

  ];




const expandRow = {

    renderer: row => (
        <div>
            <img
                className="d-block w-10 h-10"
                src={logo}
                alt="Home"
            />
            <p className="p-3 mb-2 bg-primary bg-gradient text-white rounded-5">{row.answer.substring(4, 2000)}</p>
            <Button
                className="btnFormSend"
                variant="success"
                onClick={() => newmyfun1()}>
                🔊
                         </Button>

            <Button
                className="btnFormSend"
                variant="danger"
                onClick={() => mycancel()}>
                ⏹
                        </Button>

        </div>
    ),
    showExpandColumn: true
};

//<strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>


const config1 = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
        displaystyle: true,
        delimiters: [
            ["$", "$"],
            ["`", "`"]
        ]
    }
};


function priceFormatter(cell, row) {
    return (

        <MathJaxContext config={config1}>
            <MathJax><strong style={{ color: 'blue' }}>{cell.substring(4, 2000)} </strong></MathJax>
        </MathJaxContext>



    );

}



function priceFormatter1(cell, row) {
    return (

        <strong style={{ color: 'blue' }}>{cell.substring(4, 2000)} </strong>

    );

}





var answ1 = "";
var answ2 = "";
var answ3 = "";
var answ4 = "";
var answ5 = "";

var answr = "";
var quesr = "";
var answ1r = "";
var answ2r = "";
var answ3r = "";
var answ4r = "";

var slide1 = "";
//slide =  "./Physics/12/Unit2/Slide2.jpg"
//alert(slide)



var wordIndex = 0;
var global_words = [];

var utterance = new SpeechSynthesisUtterance();
var wordIndex = 0;
var global_words = [];
//utterance.lang = 'en-US';
utterance.rate = 0.8;
utterance.pitch = 1;
utterance.volume = 1;
utterance.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray = [];
var text = "";



var wordIndex1 = 0;
var global_words1 = [];

var utterance1 = new SpeechSynthesisUtterance();
var wordIndex1 = 0;
var global_words1 = [];
//utterance.lang = 'en-UK';
utterance1.rate = 0.8;
utterance1.pitch = 1;
utterance1.volume = 1;
utterance1.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray1 = [];
var text1 = "";



function newmyfun1() {

    
    //document.getElementById("mo").show= "false"
    window.scrollTo(0, 0)
    
    //document.getElementById("t1").textContent = "";
   //document.getElementById("t2").textContent = answ;

    for (var i = 0; i < 2; i++) {

        if (i == 0) {

            if (quesr.substring(0, 4) === 'Eng-') {

                utterance1.lang = 'en-US';
                utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
            }
            if (quesr.substring(0, 4) === 'Tam-') {

                utterance1.lang = 'ta-IN';//'en-UK';

                utterance1.voice = window.speechSynthesis.voice; //IOS13 fix

                //alert(utterance1.lang);

            }

            text1 = quesr.substring(4, 2000) + "." + answr.substring(4, 2000);
            //alert(ques.substring(4, 2000));

            wordIndex1 = 0;
            var words1 = text1.split(".");

            global_words1 = words1;

            spokenTextArray1 = words1;
            utterance1.text = words1;
            //displayfun(text1);
            // displayfun(text1)


            /*
           var voices = speechSynthesis.getVoices();
         
          
           for (var i = 0; i < voices.length; i++) {
               //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
              // document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
               if (voices[i].lang == utterance1.lang ) {
                   utterance1.voice = voices[i]; // Note: some voices don't support altering params
                   utterance1.voiceURI = voices[i].voiceURI;
                  // alert(voices[i].lang);
                    break;
               }
           }
           */
            speechSynthesis.speak(utterance1);
        }

        /*
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-UK';
            }
            if (answ.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = answ.substring(4, 2000); //+ "\n" + answ;

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);


        }

     */

    }
}
var displaystr = "";

function displayfun(displaystr) {
    //var word1 = getWordAt("")
    // alert(displaystr);

    typestring = displaystr;
    
    
    //document.getElementById("t2").textContent = displaystr;
    
    //document.getElementById("he1").textContent = typestring;
    //document.getElementById("t2").focus();






    wordIndex1++;


}



utterance1.onboundary = function (event) {



    var word1 = getWordAt(ques.substring(4, 2000), event.charIndex);
    //alert(word);
    //displayfun(word1)
    //document.getElementById("t0").textContent = word1;
    //document.getElementById("t0").focus();






    wordIndex1++;

};



utterance1.onend = function () {

    //document.getElementById("t0").textContent = ques.substring(4, 2000);
    //document.getElementById("t0").focus();

   // newmyfun();

};

window.speechSynthesis.onvoiceschanged = () => {
    setVoice();
}

function setVoice() {
    //alert('ganesh');
    // utterance1.lang = 'ta-IN';
    //utterance.lang = 'ta-IN';
}


function newmyfun() {
    // document.getElementById("t1").textContent = "";
    //document.getElementById("t2").textContent = answ.substring(4, 20000);
    //alert(answ.substring(0, 4))

    for (var i = 0; i < 2; i++) {
        /*      
          if (i == 0) {
              
              if (ques.substring(0, 4) === 'Eng-') {
                
                  utterance.lang = 'en-UK';
              }
              if (ques.substring(0, 4) === 'Tam-') {
                
                  utterance.lang = 'ta-IN'//'en-UK';
  
              }
  
              text = ques.substring(4, 2000); //+ "\n" + answ;
              alert(ques.substring(4, 2000));
  
              wordIndex = 0;
              var words = text.split(" ");
  
              global_words = words;
  
              spokenTextArray = words;
              utterance.text = text;
  
              speechSynthesis.speak(utterance);
          }
          */

        if (i == 1) {
            if (answr.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-US';
                utterance.voice = window.speechSynthesis.voice; //IOS13 fix
            }
            if (answr.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';
                //setVoice();
                // utterance.voice = window.speechSynthesis.voice; //IOS13 fix
                // alert(utterance.lang);


            }

            text = answr.substring(4, 2000); //+ "\n" + answ;
            //alert(text);
            wordIndex = 0;
            var words = text.split(".");
           
            //alert(words)

            global_words = words;

            spokenTextArray = words;
            utterance.text = words//text;
            /*
            var voices = speechSynthesis.getVoices();
            

            for (var i = 0; i < voices.length; i++) {
               // alert(voices[i].voiceURI);
               
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
                //document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang) {
                    utterance.voice = voices[i]; // Note: some voices don't support altering params
                    utterance.voiceURI = voices[i].voiceURI;
                    //alert(voices[i].voiceURI);
                     break;
                }
            }
            */

            speechSynthesis.speak(utterance);


        }



    }
}




utterance.onboundary = function (event) {



    var word = getWordAt(answ.substring(4, 2000), event.charIndex);
    //alert(word);

    //document.getElementById("t2").textContent = word;
    //displayfun(word)
    // document.getElementById("Card1").textContent = word;
         
    wordIndex++;
};



utterance.onend = function () {

    //document.getElementById("t1").textContent = answ.substring(4, 2000);
  // newmyfun();
};

// Get the word of a string given the string and the index
function getWordAt(str, pos) {
    // Perform type conversions.
    str = String(str);
    pos = Number(pos) >>> 0;

    // Search for the word's beginning and end.
    var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
        return str.slice(left);
    }
    // Return the word, using the located bounds to extract it from the string.
    return str.slice(left, right + pos);
}   


function mycancel() {
    
    window.speechSynthesis.cancel();
    wordIndex = 0;

}





const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Questions and answers
        </span>
);

const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    sizePerPageList: [{ text: '1', value: 10 }, { text: '10', value: 10 }, { text: 'All', value: qadata.length }] // A numeric array is also available. the purpose of above example is custom the text
};


//const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>12th Standard சிறப்புத் தமிழ்</h3>;

//<BootstrapTable     keyField='id'     data={qadata}     columns={columns}    />

 //<SearchBar {...props.searchProps} />
//<Form.Label className="text-primary" >[வகுப்பு பலகை] </Form.Label>
//caption = {< CaptionElement caption - side='top' />}

//<Form.Control as="textarea" name="t0" id="t0" controlId="t0" disabled readonly rows={2} className="text-white bg-dark" />

//< AiOutlineFileSearch style = {{ color: 'blue', fontSize: '36px' }} onClick = { handleShow } />

//<Form.Control as="textarea" name="t1" id="t1" controlId="t1" disabled readonly rows={12} className="text-white bg-dark" />
function BasicExample() {


    function onCopyHandler(e) {
        e.preventDefault();
        e.stopPropagation();
    }



    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

    }

    const selectRow = {
        mode: 'radio',
        // hideSelectColumn: false,
        clickToSelect: true,
        //clickToExpand: true,
        // clickToEdit: true,
        color: '#def3ff',
        bgColor: 'lightblue',

        classes: (row, rowIndex) => { return row, rowIndex },
        onSelect: (rows, isSelect, rowIndex, e) => {



            if (isSelect == true) {

                window.speechSynthesis.cancel();

                ques = "";
                answ = "";

                answ1 = "";
                answ2 = "";
                answ3 = "";
                answ4 = "";
                answ5 = "";
                slide1 = "";


                answr = "";
                answ1r = "";
                quesr = "";
                answ2r = "";
                answ3r = "";
                answ4r = "";
                answ5r = "";


                window.scrollTo(0, 0)
                ques = rows.question;

                answ1 = rows.answer1

                answ2 = rows.answer2

                answ3 = rows.answer3

                answ4 = rows.answer4

                answ5 = rows.answer5

                quesr = rows.questionr


                answ1r = rows.answer1r

                answ2r = rows.answer2r

                answ3r = rows.answer3r

                answ4r = rows.answer4r

                answ5r = rows.answer5r

                // './Physics/12/Unit2/Slide2.jpg'

                slide1 = rows.slide

                if (slide1 !== 'dummy.png') {
                    //document.getElementById('text1').style.visibility = 'visible';

                    //tex_visible = 'hidden'
                    //  img_visible = 'visible'
                    //alert("1")
                    // hide
                    //div.style.visibility = 'hidden';
                    //document.getElementById("t1").style.visibility = 'visible';

                    //alert("ganesh")
                }

                if (slide1 === 'dummy.png') {

                    //tex_visible = 'visible'
                    //img_visible = 'hidden'
                    //alert("2")
                    // hide
                    //div.style.visibility = 'hidden';
                    //document.getElementById("t1").style.visibility = 'visible';

                    //alert("ganesh")
                }

                //alert(slide1);
                //logo1 = require("'"+rows.slide+"'");
                //logo1 = require(rows.slide)

                // logo1 = require('./Physics/12/Unit2/' + slide1);  -- working script
                //logo1 = slide1;
                logo1 = require('./' + slide1);

                answ = rows.answer1 + " " + rows.answer2 + " " + rows.answer3 + " " + rows.answer4 + " " + rows.answer5;

                answr = rows.answer1r + " " + rows.answer2r + " " + rows.answer3r + " " + rows.answer4r + "" + rows.answer5r;


                handleShow();



                // document.getElementById("t0").value = ques.substring(4, 2000);
                //document.getElementById("t1").textContent = answ.substring(4, 2000);
                //document.getElementById("t0").textContent = ques.substring(4, 2000);
                //document.getElementById("Card1").textContent = document.getElementById("t1").value = answ.substring(4, 2000);

                
                // var text2 = document.getElementById("t1").value;
                //alert(answ);
                // document.getElementById("t1").value = answ;
            }
        }

    };

    var ca = answ5;

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen, ] = useState(true);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(false);
    const [status1, setStatus1] = useState(false);
    const [status2, setStatus2] = useState(false);
    const [topping, setTopping] = useState("")
    const onOptionChange = e => {
        
        setTopping(e.target.value)

        if (e.target.value === answ5 ) {
            selectcolor = "green"
            setStatus2(true)
        }

        if (e.target.value !== answ5) {
            selectcolor ="red"
            setStatus2(false)
        }    }
  //  alert(answ5)
 



    const handleClose = () => {
        mycancel();
        setShow(false);
        setStatus2(false)
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        if (slide1 !== 'dummy.png') {
            setStatus(true)
            setStatus1(false)
            setShow(true)
        }
        if (slide1 === 'dummy.png') {
            setStatus(false)
            setStatus1(true)
            setShow(true)
        }
        setTopping("")
        newmyfun1()
     
    }

    


const handleDone = () => {
    //console.log(`Done after 5 loops!`)


    }

    const config = {
        loader: { load: ["input/asciimath"] },
        asciimath: {
            displaystyle: true,
            delimiters: [
                ["$", "$"],
                ["`", "`"]
            ]
        }
    };

    var equation = "$(a+b)^2$";

    equation = "$N $(m)^1$";

    return (
        <ToolkitProvider
            keyField="id"
            data={qadata}
            columns={columns}
            search
        >
            {
                props => (


        <>
                        <Typewriter
                            words={['எங்கள் இயற்பியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Physics Class Rooms', 'எங்கள் இயற்பியல் அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Physics Class Rooms', 'எங்கள் இயற்பியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Physics Class Rooms', 'எங்கள் இயற்பியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Physics Class Rooms', 'எங்கள் இயற்பியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Physics Class Rooms']}
                            loop={5}
                            cursor
                            cursorStyle='🔊..'
                            typeSpeed={70}
                            deleteSpeed={0}
                            delaySpeed={1500}


                        />

                        <div onCopy={onCopyHandler}>
             <SearchBar {...props.searchProps} />  
                            <BootstrapTable bootstrap4 responsive="sm" responsive="sm" size="sm" keyField='id' data={qadata} columns={columns} pagination={paginationFactory(options)} selectRow={selectRow} {...props.baseProps} onCopy={onCopyHandler} onClick={handleClick} />
                          

                            <Modal id="mo"
                                show={show}
                                backdrop="static"
                                onHide={handleClose}
                                keyboard={false}
                                fullscreen="true"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                size="lg"


                            >
                                


                                <Modal.Header closeButton >
                                    <MathJaxContext config={config}>
                                        <MathJax><strong style={{ color: 'green' }}> {ques.substring(4, 2000)} </strong> </MathJax> 
                                    </MathJaxContext>
                                </Modal.Header>



                                <Modal.Body>
                                    <div className="App" onCopy={onCopyHandler} >
                                        <MathJaxContext config={config}>

                                            {status ?
                                                <img
                                                    className="d-block w-50 h-50"
                                                    src={logo1}
                                                    alt="Home"
                                                    style={{ visibility: img_visible }}
                                                /> : null
                                            }  

                                        <Container>
                                    
                                        <Row>
                                        <Col>   
                                        <input
                                            type="radio"
                                            name="topping"
                                            value={answ1.substring(4, 2000)}
                                            id="a"
                                                checked={topping === answ1.substring(4, 2000)}
                                            onChange={onOptionChange}
                                        />
                                            <label htmlFor="regular"><strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ1.substring(4, 2000)}</MathJax></strong></label>
                                                    </Col>
                                                    <Col>
                                        <input
                                            type="radio"
                                            name="topping"
                                            value={answ2}
                                            id="b"
                                                checked={topping === answ2}
                                            onChange={onOptionChange}
                                        />
                                            <label htmlFor="medium"><strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ2}</MathJax></strong></label>
                                                </Col>

                                                </Row>
                                                <br />
                                                <br />
                                                    <Row>
                                                
                                               <Col>
                                        <input
                                            type="radio"
                                            name="topping"
                                                value={answ3}
                                            id="c"
                                                checked={topping === answ3}
                                            onChange={onOptionChange}
                                            />

                                          
                                            <label htmlFor="large"><strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ3}</MathJax></strong></label>
                                                    </Col>
                                                    <Col>
                                        <input
                                                type="radio"
                                                name="topping"
                                                value={answ4}
                                                id="d"
                                                checked={topping === answ4}
                                                onChange={onOptionChange}
                                            />
                                            <label htmlFor="large"><strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ4}</MathJax></strong></label>
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                               <Col>
                                             <p>
                                                    you are selected option  <strong><strong style={{ color: {selectcolor}, fontSize: '18px' }}><MathJax>{topping}</MathJax></strong> </strong>
                                            </p>
                                                    </Col>
                                                    <Col>


                                            {status2 ?
                                            <p>
                                                The correct answer is   <strong><strong style={{ color: 'Green', fontSize: '18px' }}><MathJax>{answ5}</MathJax></strong> </strong>
                                                </p> : null
                                                                
                                    
                                                        }  
                                                    </Col>
                                                </Row> 
                                                </Container>
                                        </MathJaxContext>
                                      
                                    </div>

                                    
                                    
                                   
                                   

                                </Modal.Body>
                                <Modal.Footer>
                                    <AiOutlineAudioMuted style={{ color: 'red', fontSize: '24px' }} onClick={() => window.speechSynthesis.pause()} />
                                    <AiOutlineAudio style={{ color: 'green', fontSize: '24px' }} onClick={() => window.speechSynthesis.resume()} />
                                   
                                </Modal.Footer>


                            </Modal>

                   
     
                
            </div>
                    </>
                )
            }
        </ToolkitProvider>

    );

}

export default BasicExample;