import React, { Component } from 'react';
import { render } from 'react-dom'
import Iframe from 'react-iframe'
import { Typewriter } from 'react-simple-typewriter';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";


import Chemistry12th from './Chemistry12th';

/*
<Iframe url="https://script.google.com/macros/s/AKfycby7Ty1Wwlil3x41UzeYV6R3JQ5CAhXKStvAr52elZy9wyNazgoqcF73XR3amyfx6xtEdw/exec"
    width="1020px"
    height="1020px"
    id=""
    className=""
    display="block"
   />


            <div className="d-block w-2000 h-2000">
                <Iframe src="https://script.google.com/macros/s/AKfycbx4nA_mkOGPEm9Cdg3KFkv-MirYoqLi0lGWDn82ka4OuFwUbPSSUKbkbOZzZurBSlPY/exec"
                    width="100%"
                    height="1200x"
                    id=""
                    className=""
                    display="block"
                    allow="fullscreen"
                    scrolling="yes"


                />
            </div>
*/


function Zoology() {


    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Dropdown Button
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/Chemistry12th">Action</Dropdown.Item>
                 </Dropdown.Menu>
            </Dropdown>



        
            <Routes>
        

                <Route exact path="/Chemistry12th" element={<Chemistry12th />} />

              
            </Routes>
        

        </>

    );
}

export default Zoology;


