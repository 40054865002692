import BootstrapTable from 'react-bootstrap-table-next';
import qadata from "./empty.json";
import logo3 from './1.jpg'
import logo4 from './2.jpg'
import logo5 from './3.jpg'
import Computer12th from './Computernew';
import { Carousel } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { MathJax } from 'better-react-mathjax';

const columns = [{
    dataField: 'id',
    text: 'Class Name',
    hidden: 'true'
}, {
    dataField: 'lang',
    text: 'Lanugage'
}, {
    dataField: 'Subject',
    text: 'Subjects'
    },
 {
     dataField: 'Objectives',
     text: 'Description'
    }

];

const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'red', border: '1px solid purple', padding: '0.5em' }}>பட கதைகள்</h3>;

function GroupExample() {
    return (
        <>
        
            <Typewriter
                words={['எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit : https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit:https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms']}
                loop={5}
                cursor
                cursorStyle='🔊..'
                typeSpeed={70}
                deleteSpeed={0}
                delaySpeed={1500}
                

            />

            
            <BootstrapTable keyField="id" data={qadata} caption={<CaptionElement />} columns={columns} />

      
      
        <div>
            <span class="d-xl-block bg-white">
                <div className='container-fluid' >
                    <div className="row">
                        <div className="col-sm-12">

                        </div>
                    </div>
                    <div className="row">
                        <div className="w3-card-4">
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={logo3}
                                        alt="Home"
                                    />

                                    <Carousel.Caption>
                                        <h3 className="text-danger" >Welcome to VirtualKalvi.com</h3>
                                        <p>அறிவே ஆற்றல்.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={logo4}
                                        alt="Home2"
                                    />

                                    <Carousel.Caption>
                                        <h3 className="text-danger" >Learning is essential to all.</h3>
                                        <p>Improve your skills by learning, all we get the knowlege and value</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100 h-100"
                                        src={logo5}
                                        alt="Home3"
                                    />
                                    <Carousel.Caption>
                                        <h3 className="text-danger" >கேடில் விழுச்செல்வம் கல்வி யொருவற்கு</h3>
                                        <h3 className="text-danger" >மாடல்ல மற்றை யவை.</h3>
                                        <p>ஒருவனுக்கு அழிவு இல்லாத சிறந்த செல்வம் கல்வியே ஆகும்</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        </>
    );
}

export default GroupExample;