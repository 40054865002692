import BootstrapTable from 'react-bootstrap-table-next';

import qadata from "./empty.json";

import { Carousel } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { MathJax } from 'better-react-mathjax';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';




import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { GiMaterialsScience, AiOutlineAudioMuted } from 'react-icons/ai';

import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from './Home1';
import Tamil from './Tamil1';
import Tamil12th from './Tamil12th';
import Math12 from './Math12';
import Math122 from './Math122';

import Computer12th from './Computernew';
import Computer12th_onemark from './Computer12th_onemark';

import Chemistry12th from './Chemistry12th';
import Chemistry12th2 from './Chemistry12th2';
import Chemistry12th3 from './Chemistry12th3';
//import Chemistry12th1m from './Chemistry12th1m';
import PhysicsAudio from './Phy12audio';
import Physics1mark from './Phy12_onemark';
import Zoology12th from './Zoology12th';
import Zoology12th2 from './Zoology12th2';
import Zoology12th3 from './Zoology12th3';
import Zoology12th4 from './Zoology12th4';
import Physics from './Phy2mark';
import Physicsimp from './Physicsimp';


const columns = [{
    dataField: 'id',
    text: 'Class Name',
    hidden: 'true'
}, {
    dataField: 'lang',
    text: 'Lanugage'
}, {
    dataField: 'Subject',
    text: 'Subjects'
    },
 {
     dataField: 'Objectives',
     text: 'Description'
    }

];

const CaptionElement = () => <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'red', border: '1px solid purple', padding: '0.5em' }}>"அன்பு என்பது ஆகாயம் போன்றது!"</h5>;


const CaptionElement1 = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>எட்டி விடும் தூரத்தில் வெற்றியும் இல்லை! அதை விட்டு விடும் எண்ணத்தில் நானும் இல்லை !</h3>;

function GroupExample() {
    return (
        <>
        
            <Typewriter
                words={['எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit : https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit:https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms']}
                loop={5}
                cursor
                cursorStyle='🔊..'
                typeSpeed={70}
                deleteSpeed={0}
                delaySpeed={1500}
                

            />

            
            <BootstrapTable keyField="id" data={qadata} caption={<CaptionElement />} columns={columns} />


            <Container background-color="purple">
                <Row>
                    <Col xs={6} md={4} color='red' >
                        <Card>
                            <Card.Header>
                                <Card.Title style={{ color: 'red', fontSize: '18px' }}>12th Grade - Computer Science</Card.Title>
                                

                            </Card.Header>
                            <Card.Body>
                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Computer12th_onemark">One Mark Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Computer12th">Five Mark Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </Card.Body>
                        </Card>
                    </Col>


                    <Col xs={6} md={4} color='red' >
                        <Card>
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Physics</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Physics 1 mark">One Mark Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Physics">Two Mark Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./PhysicsAudio">Two Mark - Audio</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="/Physicsimp">Important - Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </Card.Body>
                        </Card>
                    </Col>

                 
                </Row>
            </Container>
            
            <br />
            <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>எட்டி விடும் தூரத்தில் வெற்றியும் இல்லை! அதை விட்டு விடும் எண்ணத்தில் நானும் இல்லை !</h5>;
            
            <Container background-color="purple">
                <Row>
                    <Col xs={6} md={4} color='red' >
                        <Card>
                            <Card.Header>
                                <Card.Title style={{ color: 'red', fontSize: '18px' }}>12th Grade - Chemistory</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Chemistry12th">One Mark - Practice test 1</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Chemistry12th2">One Mark - Practice test 2</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Chemistry12th3">Five Mark qustions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </Card.Body>
                        </Card>
                    </Col>


                    <Col xs={6} md={4} color='red' >
                        <Card>
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Zoology</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Zoology12th">One Mark - Practic test 1</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Zoology12th2">One Mark - Practic test 2</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Zoology12th4">One Mark - Practic test 3</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Zoology12th3">5 Mark- Questions</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                            </Card.Body>
                        </Card>
                    </Col>


                </Row>
            </Container>


            <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'green', border: '1px solid purple', padding: '0.5em' }}>எங்கே விழுந்தாய் என்று பார்க்க வேண்டியதில்லை, எங்கே வழுக்கியது என்று தான் பார்க்க வேண்டும்</h5>;
          
            <Container background-color="purple">
                <Row>
                    <Col xs={6} md={4} color='red' >
                        <Card>
                            <Card.Header>
                                <Card.Title style={{ color: 'red', fontSize: '18px' }} >12th Grade - Tamil</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Tamil12th">தமிழ் கேள்வி பதில் - 1</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Nav variant="pills" defaultActiveKey="#first">
                                    <Nav.Item>
                                        <Nav.Link href="./Tamil">பொது அறிவு</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                

                            </Card.Body>
                        </Card>
                    </Col>


            
                </Row>
            </Container>
            

      
       
        </>
    );
}

export default GroupExample;