import Alert from 'react-bootstrap/Alert';
import BootstrapTable from 'react-bootstrap-table-next';
//import Card from 'react-bootstrap/Card';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import qadata from "./Chem.json";
import React, { Component, useState } from "react";
import { Form, Button } from "react-bootstrap";
import logo from './Answer.png';
//import { FcSpeaker } from 'react-icons/fc';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


import Modal from 'react-bootstrap/Modal';



import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;

var word1 = "Welcome to Virtual kalvi"

var readv = "0"

const columns = [
    {
        dataField: 'class',
        text: 'Class Name',
        hidden: true

    },
    {
        dataField: 'Subject',
        text: 'Subject Name',
        hidden: true


    }, {
        dataField:'chapter',
        text: 'Chapter',
        
    },
    {
        dataField: 'question',
        text: 'Question',
        formatter: priceFormatter

    },
    {
        dataField: 'answer',
        text: 'Answer',
        hidden: true
         
    }
    /*
    {
        dataField: 'Read',
        text: ''
        formatter: (cellContent, row) => (
            <div className="Read1">
                <Button
                    className="btnFormSend"
                    variant="success"
                    onClick={() => newmyfun1()}>
                    🔊
                         </Button>

                <Button
                    className="btnFormSend"
                    variant="danger"
                    width="25%"
                    height="25%"
                    onClick={() => mycancel()}>
                    ⏹
                        </Button>
            </div>
        )
    },
    */
  

];

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Centered Modal</h4>
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
        </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}




function priceFormatter(cell, row) {
    return (
     
        <span>
            <strong style={{ color: 'blue' }}>{cell.substring(4, 2000)} </strong>
        </span>
   
    );
   
}





var ques = "";
var answ = "";



var wordIndex = 0;
var global_words = [];

var utterance = new SpeechSynthesisUtterance();
var wordIndex = 0;
var global_words = [];
//utterance.lang = 'en-US';
utterance.rate = 0.8;
utterance.pitch = 1;
utterance.volume = 1;
utterance.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray = [];
var text = "";



var wordIndex1 = 0;
var global_words1 = [];

var utterance1 = new SpeechSynthesisUtterance();
var wordIndex1 = 0;
var global_words1 = [];
//utterance.lang = 'en-UK';
utterance1.rate = 0.8;
utterance1.pitch = 1;
utterance1.volume = 1;
utterance1.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray1 = [];
var text1 = "";


/*
const popover = (   
    <Popover id="popover-basic">
        <Popover.Header as="h3" id="ph1">Popover right</Popover.Header>
        <Popover.Body>
            And here's some <strong>amazing</strong> content. It's very engaging.
            right?
    </Popover.Body>
    </Popover>
);
*/

function newmyfun2(tread) {
  
    //alert(row.answer);
    

    /*
    if (tread.substring(0, 4) === 'Eng-') {
        utterance1.lang = 'en-US';
        utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
        text1 = tread.substring(4, 2000); //+ "\n" + answ;
       
        //alert(ques.substring(4, 2000));

        wordIndex1 = 0;
        var words1 = text1.split(" ");

        global_words1 = words1;

        spokenTextArray1 = words1;
        utterance1.text = text1;
        speechSynthesis.speak(utterance1);
        
    }
    */
}



function newmyfun1() {
    document.getElementById("t0").value = "";
    document.getElementById("t1").value = "";
    //document.getElementById("t1").value = answ;

    for (var i = 0; i < 2; i++) {

        if (i == 0) {

            if (ques.substring(0, 4) === 'Eng-') {

                utterance1.lang = 'en-US';
                utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
            }
            if (ques.substring(0, 4) === 'Tam-') {

                utterance1.lang = 'ta-IN';//'en-UK';
                
                utterance1.voice = window.speechSynthesis.voice; //IOS13 fix

                //alert(utterance1.lang);

            }

            text1 = ques.substring(4, 2000); //+ "\n" + answ;
            //alert(ques.substring(4, 2000));

            wordIndex1 = 0;
            var words1 = text1.split(" ");

            global_words1 = words1;

            spokenTextArray1 = words1;
            utterance1.text = text1;

             /*
            var voices = speechSynthesis.getVoices();
          
           
            for (var i = 0; i < voices.length; i++) {
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
               // document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang ) {
                    utterance1.voice = voices[i]; // Note: some voices don't support altering params
                    utterance1.voiceURI = voices[i].voiceURI;
                   // alert(voices[i].lang);
                     break;
                }
            }
            */
            speechSynthesis.speak(utterance1);
        }

        /*
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-UK';
            }
            if (answ.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = answ.substring(4, 2000); //+ "\n" + answ;

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);


        }

     */

    }
}

/*

utterance1.onboundary = function (event) {


   // document.getElementById("ah1").value = "Ganesh"; 
     word1 = getWordAt(text1, event.charIndex);



    //alert(word);

   // document.getElementById("ph1").text = "Ganesh";    
  





    wordIndex1++;
};



utterance1.onend = function () {

   // document.getElementById("t0").value = ques.substring(4,2000);
   //newmyfun();

};
*/
window.speechSynthesis.onvoiceschanged = () => {
    setVoice();
}

function setVoice() 
{
    //alert('ganesh');
   // utterance1.lang = 'ta-IN';
    //utterance.lang = 'ta-IN';
}   


function newmyfun() {
    document.getElementById("t1").value = "";
    //document.getElementById("t1").value = answ;
   
    for (var i = 0; i < 2; i++) {
      /*      
        if (i == 0) {
            
            if (ques.substring(0, 4) === 'Eng-') {
              
                utterance.lang = 'en-UK';
            }
            if (ques.substring(0, 4) === 'Tam-') {
              
                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = ques.substring(4, 2000); //+ "\n" + answ;
            alert(ques.substring(4, 2000));

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);
        }
        */
        
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {
               
                utterance.lang = 'en-US';
                utterance.voice = window.speechSynthesis.voice; //IOS13 fix
            }
            if (answ.substring(0, 4) === 'Tam-') {
                
                utterance.lang = 'ta-IN'//'en-UK';
                //setVoice();
               // utterance.voice = window.speechSynthesis.voice; //IOS13 fix
               // alert(utterance.lang);
              

            }
            
            text = answ.substring(4,2000); //+ "\n" + answ;
            //alert(text);
            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;
            /*
            var voices = speechSynthesis.getVoices();
            

            for (var i = 0; i < voices.length; i++) {
               // alert(voices[i].voiceURI);
               
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
                //document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang) {
                    utterance.voice = voices[i]; // Note: some voices don't support altering params
                    utterance.voiceURI = voices[i].voiceURI;
                    //alert(voices[i].voiceURI);
                     break;
                }
            }
            */

            speechSynthesis.speak(utterance);


        }

      

    }
}



    utterance.onboundary = function (event) {



        var word = getWordAt(answ.substring(4, 2000), event.charIndex);
        //alert(word);


        document.getElementById("t1").value = word;
        // document.getElementById("Card1").textContent = word;




        wordIndex++;
    };



    utterance.onend = function () {

        document.getElementById("t1").value = answ.substring(4, 2000);

        
    };



// Get the word of a string given the string and the index
function getWordAt(str, pos) {

    str = String(str);
    pos = Number(pos) >>> 0;


    var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
 

        return str;
    }
 
    return str.slice(0, pos);
}


function mycancel() {
   // window.speechSynthesis.cancel();
    // wordIndex = 0;
    
    

}



const selectRow = {
    mode: 'radio',
    // hideSelectColumn: false,
    clickToSelect: true,
    clickToExpand: true,
    // clickToEdit: true,
    color: '#def3ff',
    bgColor: 'lightgreen',

    classes: (row, rowIndex) => { return row, rowIndex },
    onSelect: (rows, isSelect, rowIndex, e) => {
        if (isSelect == true) {
           // ques = "";
           // answ = "";
           // ques = rows.question;
           // answ = rows.answer;
           // document.getElementById("t0").value = ques.substring(4,2000);
           // document.getElementById("t1").value = answ.substring(4, 2000);
            //document.getElementById("Card1").textContent = document.getElementById("t1").value = answ.substring(4, 2000);

            window.speechSynthesis.cancel();
           // var text2 = document.getElementById("t1").value;
          //  alert(text2);
           // document.getElementById("t1").value = answ;
        }
    }

};

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Questions and answers
        </span>
);



    const options = {
        paginationSize: 1,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        sizePerPageList: [{ text: '1', value: 2 }, { text: '4', value: 4 }, { text: 'All', value: qadata.length }] // A numeric array is also available. the purpose of above example is custom the text

    };


const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>Chemistry Question and Answer</h3>;

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Popover right</Popover.Header>
        <Popover.Body>
            And here's some <strong>amazing</strong> content. It's very engaging.
            right?
                              </Popover.Body>
    </Popover>
);

const rowEvents = {
    onClick: (e, row, rowIndex) => {
        alert(row.id);
  }
};



//<BootstrapTable     keyField='id'     data={qadata}     columns={columns}     expandRow={expandRow} />

//  <SearchBar {...props.searchProps} />
//<Form.Label className="text-primary" >[Digital Board] </Form.Label>
//caption = {< CaptionElement />}
const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
        <div>



            <div class="w3-container w3-blue">
                <Form>
                    <Form.Group className="mb-3" >
                        <Form.Control as="textarea" disabled readonly rows={2} id={"q"+row.id} className="text-white bg-dark" value={row.question.substring(4, 2000)} />
                        <textarea id="tq1">{row.question.substring(4, 2000)}</textarea>
                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                            <Button variant="success">Click me to see</Button>
                        </OverlayTrigger>
                    
                    </Form.Group>
                </Form>
                <Button onClick={
                    
                    function aa() {

                        readv = '1';
                        const readrow =  "q" + row.id

                       

                         var inputtext1 = "";
                        var finaltext1 = "";
                         inputtext1 = row.question
                         finaltext1 = inputtext1.substring(4, 2000)
                        if (inputtext1.substring(0, 4) === 'Eng-') {
                            utterance1.lang = 'en-US';
                            utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
                            text1 = inputtext1.substring(4, 2000); //+ "\n" + answ;

                            //alert(ques.substring(4, 2000));

                            wordIndex1 = 0;
                            var words1 = text1.split(" ");

                            global_words1 = words1;

                            spokenTextArray1 = words1;
                            utterance1.text = text1;
                            speechSynthesis.speak(utterance1);

                          


                                utterance1.onboundary = function (event) {


                                    // document.getElementById("ah1").value = "Ganesh"; 
                                    word1 = getWordAt(text1, event.charIndex);



                                    //alert(word);
                                    
                                    document.getElementById(readrow).value = word1;
                                       // document.getElementById("q" + row.id).value = word1;
                                        //document.getElementById("ah1").value = word1;
                                    




                                    wordIndex1++;
                                };


                            
                            utterance1.onend = function () {
                                document.getElementById(readrow).value = finaltext1;
                                    //document.getElementById("q" + row.id).value = finaltext1;
                                }
                                    // document.getElementById("t0").value = ques.substring(4,2000);
                                    //newmyfun();

                            

                            }
                        

                    }
                }>read</Button>
            </div>

        </div>


    ),
    showExpandColumn: true


};

function BasicExample() {
    const [modalShow, setModalShow] = React.useState(false);
    readv = "0"
    
    window.speechSynthesis.cancel()
 


    return (

        <ToolkitProvider
            keyField="id"
            data={qadata}
            columns={columns}
            search={{
                searchFormatted: true
            }}
        >
            {
                props => (
                    <div>
                        
                             
                        <hr />
                        <h4 style={{ borderRadius: '0.25em', textAlign: 'left', color: 'red', border: '1px solid purple', padding: '0.5em' }}>[Chemistry - Question and Answer]</h4>
                  
                        <BootstrapTable responsive="sm" bordered="true" hover="true" responsive="sm" size="sm" keyField='id' data={qadata} columns={columns} pagination={paginationFactory(options)} selectRow={selectRow} {...props.baseProps} expandRow={expandRow} rowEvents={rowEvents} />
                        <SearchBar {...props.searchProps} />
                       
                    </div>
                )
            }
        </ToolkitProvider>
         

   
    );
}

export default BasicExample;