import Typewriter from 'typewriter-effect';

const TypeWriter = ({ texts }) => (
  <Typewriter
    options={{
      strings: texts,
      autoStart: true,
      cursor:'....',
      pauseFor: 15000,
      loop: true,
            typeSpeed: 1000000000000000000000000000,
      deleteSpeed: 0,
      changeDelay:0,
      wrapperClassName: 'head_tags'
    }}
    />
); 



export default TypeWriter;