import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from './Home3';
import Tamil from './Tamil1';
import Tapage from './Tapage';
import Tamil12th from './Tamil12th';
import Tamil12th1 from './Tamil12th1';
import Math12 from './Math12';
import Math122 from './Math122';

import Computer12th from './Computernew';
import Computer12th_onemark from './Computer12th_onemark';

import Chemistry12th from './Chemistry12th';
import Chemistry12th2 from './Chemistry12th2';
import Chemistry12th3 from './Chemistry12th3';
//import Chemistry12th1m from './Chemistry12th1m';
import PhysicsAudio from './Phy12audio';
import Physics1mark from './Phy12_onemark';
import Zoology12th from './Zoology12th';
import Zoology12th2 from './Zoology12th2';
import Zoology12th3 from './Zoology12th3';
import Zoology12th4 from './Zoology12th4';
import Zoology12th5 from './Zoology12th5';
import Physics from './Phy2mark';
import Physicsimp from './Physicsimp';
import Tamil12th3 from './Tamil12th3';
import Tamillearning from './Tamillearning'; 
import Tamilcolor from './Tamilcolor';


import logo from './vkl.png'

//<Link className="nav-link" to="/screener">Screener</Link
//<NavDropdown.Item as={Link} to="/Tamil">தமிழ்</NavDropdown.Item>
//<NavDropdown.Item as={Link} to="/Chemistry">Chemistry</NavDropdown.Item>

//<NavDropdown.Item as={Link} to="/Chemistry12th1m">One mark qustions</NavDropdown.Item>
//<Route exact path="/Chemistry12th1m" element={< Chemistry12th1m />} />

//<Navbar.Brand href="/">VirturalKalvi.com</Navbar.Brand>
function CollapsibleExample() {
    return (
        <>
            
            <BrowserRouter>
                <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
            <Container>
                        <Navbar.Brand href="/">                                                    
                         VirutalKalvi.com
                        </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                            
                                

                    </Nav>
                    
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Tamil" element={<Tamil />} />
                    <Route exact path="/Physics 1 mark" element={<Physics1mark />} />
                    <Route exact path="/Physics" element={<Physics />} />

                    <Route exact path="/Chemistry12th" element={<Chemistry12th />} />

                    <Route exact path="/Chemistry12th2" element={<Chemistry12th2 />} />

                    <Route exact path="/PhysicsAudio" element={<PhysicsAudio />} />

                    <Route exact path="/Physicsimp" element={<Physicsimp />} />

                    <Route exact path="/Computer12th" element={< Computer12th />} />
                    <Route exact path="/Computer12th_onemark" element={< Computer12th_onemark />} />
                    <Route exact path="/Zoology12th" element={< Zoology12th />} />
                    <Route exact path="/Zoology12th2" element={< Zoology12th2 />} />
                    <Route exact path="/Zoology12th3" element={< Zoology12th3 />} />
                    <Route exact path="/Zoology12th4" element={< Zoology12th4 />} />
                    <Route exact path="/Zoology12th5" element={< Zoology12th5 />} />

                    <Route exact path="/Chemistry12th3" element={< Chemistry12th3 />} /> 
                  
                    
                    <Route exact path="/Tamil12th" element={< Tamil12th />} />
                    <Route exact path="/Tamil12th1" element={< Tamil12th1 />} />
                    <Route exact path="/Tamil12th3" element={< Tamil12th3 />} />
                    <Route exact path="/Math12" element={< Math12 />} />
                    <Route exact path="/Math122" element={< Math122 />} />
                    <Route exact path="/Tapage" element={< Tapage />} />
                    <Route exact path="/Tamillearning" element={< Tamillearning />} />
                    <Route exact path="/Tamilcolor" element={<Tamilcolor />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default CollapsibleExample;
