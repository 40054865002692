 import BootstrapTable from 'react-bootstrap-table-next';
import qadata from "./empty.json";
import maindata from "./classroom.json";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Carousel } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { MathJax } from 'better-react-mathjax';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

import logocs from './cs.png'
import logoph from './ph.png'
import logoch from './ch.png'
import logozo from './zo.png'
import logogk from './gk.png'
import logota from './ta.png'


import { AiOutlineWifi} from 'react-icons/ai';




import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { GiMaterialsScience, AiOutlineAudioMuted } from 'react-icons/ai';


import Home from './Home2';
import Tapage from './Tapage';
import Tamil from './Tamil1';
import Tamil12th from './Tamil12th';
import Math12 from './Math12';
import Math122 from './Math122';

import Computer12th from './Computernew';
import Computer12th_onemark from './Computer12th_onemark';

import Chemistry12th from './Chemistry12th';
import Chemistry12th2 from './Chemistry12th2';
import Chemistry12th3 from './Chemistry12th3';
//import Chemistry12th1m from './Chemistry12th1m';
import PhysicsAudio from './Phy12audio';
import Physics1mark from './Phy12_onemark';
import Zoology12th from './Zoology12th';
import Zoology12th2 from './Zoology12th2';
import Zoology12th3 from './Zoology12th3';
import Zoology12th4 from './Zoology12th4';
import Physics from './Phy2mark';
import Physicsimp from './Physicsimp';
import Tamil12th1 from './Tamil12th1';

const columns = [{
    dataField: 'id',
    text: 'Class Name',
    hidden: 'true'
}, {
    dataField: 'lang',
    text: 'Lanugage'
}, {
    dataField: 'Subject',
        text: 'Subjects'

    },
 {
     dataField: 'Objectives',
     text: 'Description'
    }


]

const maincolumns = [{
    dataField: 'id',
    text: 'Class Name',
    hidden: 'true'
}, {
    datafield: 'classname',
        text: 'Classsss',
        hidden: 'true'
    },
    {
    dataField: 'Subject',
        text: 'Subjects'
        
    },
    {
        dataField: 'Pagedesc',
        text: 'Study Materials'
        
    },
    {
        dataField: 'Pagehistry',
        text: 'Page Histry'

    },
    {
        dataField: 'Page',
        text: 'Click Here',
        formatter: priceFormatter
    }

];



const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    clickToExpand: true,
    classes: (row, rowIndex) => { return row, rowIndex },
    onSelect: (rows, isSelect, rowIndex, e) => {

        <Link to={rows.Page}>Go to Aboutpage</Link>

        



        
    }


};

const expandRow = {
    showExpandColumn: true,
    renderer: row => (
        <div>
            
            <Link to={row.Page}><AiOutlineWifi style={{ color: 'red', fontSize: '24px' }} />{row.Pagedesc}</Link>
            
        </div>
    )
};

//<strong style={{ color: 'blue' }}>{cell}  </strong> 
//<Link to='/Computer12th'>Go to Aboutpage</Link>
function priceFormatter(cell, row) {
    return (

        <Link to={cell}><AiOutlineWifi style={{ color: 'red', fontSize: '24px' }} /></Link>
        
        )
}




const CaptionElement = () => <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>"அன்பு என்பது ஆகாயம் போன்றது!"</h5>;


const CaptionElement1 = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>எட்டி விடும் தூரத்தில் வெற்றியும் இல்லை! அதை விட்டு விடும் எண்ணத்தில் நானும் இல்லை !</h3>;

//xs={6} md={4} color='red'
function GroupExample() {
    return (
        <>




            
            <Typewriter
                words={['எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit : https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms', 'visit:https://biology-zoology.blogspot.com/', 'எங்கள் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our Class Rooms']}
                loop={5}
                cursor
                cursorStyle='🔊..'
                typeSpeed={70}
                deleteSpeed={0}
                delaySpeed={1500}
                

            />

            
            <BootstrapTable keyField="id" data={qadata} caption={<CaptionElement />} columns={columns} />

            <BootstrapTable keyField="id" data={maindata} caption={<CaptionElement />} columns={maincolumns}   />

            <Container background-color="purple">
                <Row>
                    <Col  >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logocs} />
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Computer Science</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Computer12th">5 Mark Questions</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Computer12th_onemark">One Mark Questions</Dropdown.Item>
                                    </Dropdown.Menu>

                                    
                                    
                                    
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>

                    <Col  >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logoph} />
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Physics</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Physics 1 mark">1 Mark Questions</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Physics">2 Mark Questions</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/PhysicsAudio">Physics Audio book</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Physicsimp">Important Questions</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>


            <br />
            <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>எட்டி விடும் தூரத்தில் வெற்றியும் இல்லை! அதை விட்டு விடும் எண்ணத்தில் நானும் இல்லை !</h5>;

                 <Container background-color="purple">
                <Row>
                    <Col >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logozo} />
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Zoology</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Zoology12th">Mock examination-1</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Zoology12th2">Mock examination-2</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/Zoology12th4">Mock examination-3</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Zoology12th3">5 Mark Questions</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>

                    <Col  >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logoch} />
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - chemistry</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Chemistry12th">Mock examination1</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Chemistry12th2">Mock examination2</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/Chemistry12th3">5 Mark Questions</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>

            <br />

            <h5 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'blue', border: '1px solid purple', padding: '0.5em' }}>எங்கே விழுந்தாய் என்று பார்க்க வேண்டியதில்லை, எங்கே வழுக்கியது என்று தான் பார்க்க வேண்டும்</h5>;

                  <Container background-color="purple">
                <Row>
                    <Col >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logota}/>
                            <Card.Header>
                                <Card.Title style={{ color: 'blue', fontSize: '18px' }}>12th Grade - Tamil</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/Tamil12th">மாதிரி பரீட்சை -1 </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/Tamil12th1">மாதிரி பரீட்சை -2 </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>
                    <br />
                    <Col >
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={logogk} />
                            <Card.Header>
                            <Card.Title style={{ color: 'blue', fontSize: '18px' }}>General Knowledge</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Study materials
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/Tamil">General Knowlege-1</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>




                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>

            <Routes>


                <Route exact path="/Chemistry12th" element={<Chemistry12th />} />
                <Route exact path="/Computer12th" element={< Computer12th />} />
                <Route exact path="/Computer12th_onemark" element={< Computer12th_onemark />} />
                <Route exact path="/Physics 1 mark" element={<Physics1mark />} />
                <Route exact path="/Physics" element={<Physics />} />
                <Route exact path="/PhysicsAudio" element={<PhysicsAudio />} />
                <Route exact path="/Physicsimp" element={<Physicsimp />} />
                <Route exact path="/Zoology12th" element={< Zoology12th />} />
                <Route exact path="/Zoology12th2" element={< Zoology12th2 />} />
                <Route exact path="/Zoology12th3" element={< Zoology12th3 />} />
                <Route exact path="/Zoology12th4" element={< Zoology12th4 />} />

                <Route exact path="/Chemistry12th3" element={< Chemistry12th3 />} /> 
                <Route exact path="/Chemistry12th" element={<Chemistry12th />} />
                <Route exact path="/Chemistry12th2" element={<Chemistry12th2 />} />
                <Route exact path="/Tamil12th" element={< Tamil12th />} />
                <Route exact path="/Tamil12th1" element={< Tamil12th1 />} />
                <Route exact path="/Tamil" element={< Tamil />} />
                <Route exact path="/Tapage" element={< Tapage />} />
            </Routes>
           
       
        </>
    );
}

export default GroupExample;