import React, { Component } from 'react';
import { render } from 'react-dom'
import Iframe from 'react-iframe'
import { Typewriter } from 'react-simple-typewriter';

	/*
	<Iframe url="https://script.google.com/macros/s/AKfycby7Ty1Wwlil3x41UzeYV6R3JQ5CAhXKStvAr52elZy9wyNazgoqcF73XR3amyfx6xtEdw/exec"
        width="1020px"
        height="1020px"
        id=""
        className=""
        display="block"
       />
*/


function Zoology() {
    window.scrollTo(0, 0)

    return (
        <>

        <div className="d-block w-2000 h-2000"> 
            <Iframe src="https://script.google.com/macros/s/AKfycbxcOu5vTxQvrPViQHst-LYwAE1h5MkMuygmaIclQ8FhTT7e5gC4cQq3gVub-IGUYuPeOg/exec"
            width="100%"
            height="1200x"
            id=""
            className=""
            display="block"
            allow="fullscreen"
            scrolling="yes" 
           
            
        />
            </div> 
  </>

    );
}

export default Zoology;


