import BootstrapTable from 'react-bootstrap-table-next';
//import Card from 'react-bootstrap/Card';re
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import qadata from "./Zoology12th.json";
import React, { Component, useState } from "react";
import { Form, Button } from "react-bootstrap";
import logo from './Answer_t.png'
import { Row, Col, Container } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
//import TypeWriter from "./typewriter"
import { Typewriter } from 'react-simple-typewriter';
import Card from 'react-bootstrap/Card';
//import logos1 from './Physics/12/Unit2/Slide2.jpg'
import { MathJax, MathJaxContext } from "better-react-mathjax";



import { AiOutlineAudioMuted, AiOutlineAudio, AiOutlineFileSearch } from 'react-icons/ai';



//import logo3 from { slide }
import paginationFactory from 'react-bootstrap-table2-paginator';

var kk = 'VK.PNG'

var logo1 = "";

var ques = "";
var answ = "";
var answ5r = "";
var answr = "";
answr = "Please select any question and click answer button"

//logo1 = require('./Physics/12/Unit2/'+kk);  --- Working code

var img_visible = 'visible';


logo1 = require('./VK.PNG');


const { SearchBar } = Search;

var typestring = "";

typestring = ""

var moshow = "false"
var logos = "";



const columns = [
    {
        dataField: 'class',
        text: 'Class Name',
        hidden: true

    },
    {
        dataField: 'Subject',
        text: 'Subject Name',
        hidden: true


    }, {
        dataField: 'chapter',
        text: 'Chapter',

    },
    {
        dataField: 'question',
        text: 'Question',
        formatter: priceFormatter

    },

    {
        dataField: 'answer1',
        text: 'Answer1',
        hidden: true

    },
    {
        dataField: 'answer2',
        text: 'Answer2',
        hidden: true

    },
    {
        dataField: 'answer3',
        text: 'Answer3',
        hidden: true

    },
    {
        dataField: 'answer4',
        text: 'Answer4',
        hidden: true

    },
    {
        dataField: 'answer5',
        text: 'Answer5',
        hidden: true

    },

    {
        dataField: 'answer6',
        text: 'Answer6',
        hidden: true

    },
    {
        dataField: 'answer7',
        text: 'Answer7',
        hidden: true

    },
    {
        dataField: 'answer8',
        text: 'Answer8',
        hidden: true

    },
    {
        dataField: 'answer9',
        text: 'Answer9',
        hidden: true

    },
    {
        dataField: 'answer10',
        text: 'Answer10',
        hidden: true

    },


    {
        dataField: 'answer11',
        text: 'Answer11',
        hidden: true

    },
    {
        dataField: 'answer12',
        text: 'Answer12',
        hidden: true

    },
    {
        dataField: 'answer13',
        text: 'Answer13',
        hidden: true

    },
    {
        dataField: 'answer14',
        text: 'Answer14',
        hidden: true

    },
    {
        dataField: 'answer15',
        text: 'Answer15',
        hidden: true

    },


    {
        dataField: 'answer16',
        text: 'Answer16',
        hidden: true

    },
    {
        dataField: 'answer17',
        text: 'Answer2',
        hidden: true

    },
    {
        dataField: 'answer18',
        text: 'Answer18',
        hidden: true

    },
    {
        dataField: 'answer19',
        text: 'Answer19',
        hidden: true

    },
    {
        dataField: 'answer20',
        text: 'Answer20',
        hidden: true

    },

    {
        dataField: 'questionr',
        text: 'questionr',
        hidden: true

    },

    {
        dataField: 'answer1r',
        text: 'Answer1r',
        hidden: true

    },
    {
        dataField: 'answer2r',
        text: 'Answer2r',
        hidden: true

    },
    {
        dataField: 'answer3r',
        text: 'Answer3r',
        hidden: true

    },
    {
        dataField: 'answer4r',
        text: 'Answer4r',
        hidden: true

    },
    {
        dataField: 'answer5r',
        text: 'Answer5r',
        hidden: true

    },
    {
        dataField: 'answer6r',
        text: 'Answer6r',
        hidden: true

    },
    {
        dataField: 'answer7r',
        text: 'Answer7r',
        hidden: true

    },
    {
        dataField: 'answer8r',
        text: 'Answer8r',
        hidden: true

    },
    {
        dataField: 'answer9r',
        text: 'Answer9r',
        hidden: true

    },
    {
        dataField: 'answer10r',
        text: 'Answer10r',
        hidden: true

    },
    {
        dataField: 'answer11r',
        text: 'Answer11r',
        hidden: true

    },
    {
        dataField: 'answer12r',
        text: 'Answer12r',
        hidden: true

    },
    {
        dataField: 'answer13r',
        text: 'Answer13r',
        hidden: true

    },
    {
        dataField: 'answer14r',
        text: 'Answer14r',
        hidden: true

    },
    {
        dataField: 'answer15r',
        text: 'Answer15r',
        hidden: true

    },
    {
        dataField: 'answer16r',
        text: 'Answer16r',
        hidden: true

    },
    {
        dataField: 'answer17r',
        text: 'Answer17r',
        hidden: true

    },
    {
        dataField: 'answer18r',
        text: 'Answer18r',
        hidden: true

    },
    {
        dataField: 'answer19r',
        text: 'Answer19r',
        hidden: true

    },
    {
        dataField: 'answer20r',
        text: 'Answer20r',
        hidden: true

    },
    {
        dataField: 'slide',
        text: 'slide',
        hidden: true

    }
   
    /*
    
    {
        dataField: 'Read',
        text: '',
        formatter: (cellContent, row) => (
            <div className="Read1">
                <Button
                    className="btnFormSend"
                    variant="success"
                    onClick={() => newmyfun1()}>
                    🔊
                         </Button>

                <Button
                    className="btnFormSend"
                    variant="danger"
                    onClick={() => mycancel()}>
                    ⏹
                        </Button>

            </div>
        )
    },
    */

];




const expandRow = {

    renderer: row => (
        <div>
            <img
                className="d-block w-10 h-10"
                src={logo}
                alt="Home"
            />
            <p className="p-3 mb-2 bg-primary bg-gradient text-white rounded-5">{row.answer.substring(4, 2000)}</p>
            <Button
                className="btnFormSend"
                variant="success"
                onClick={() => newmyfun1()}>
                🔊
                         </Button>

            <Button
                className="btnFormSend"
                variant="danger"
                onClick={() => mycancel()}>
                ⏹
                        </Button>

        </div>
    ),
    showExpandColumn: true
};

//<strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>

function priceFormatter(cell, row) {
    return (

        <strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>

    );

}



function priceFormatter1(cell, row) {
    return (

        <strong style={{ color: 'blue' }}>{cell.substring(4, 2000)} </strong>

    );

}





var answ1 = "";
var answ2 = "";
var answ3 = "";
var answ4 = "";
var answ5 = "";

var answ6 = "";
var answ7 = "";
var answ8 = "";
var answ9 = "";
var answ10 = "";


var answ11 = "";
var answ12 = "";
var answ13 = "";
var answ14 = "";
var answ15 = "";

var answ16 = "";
var answ17 = "";
var answ18 = "";
var answ19 = "";
var answ20 = "";

var answr = "";

var quesr = "";

var answ1r = "";
var answ2r = "";
var answ3r = "";
var answ4r = "";


var answ5r = "";
var answ6r = "";
var answ7r = "";
var answ8r = "";
var answ9r = "";

var answ10r = "";
var answ11r = "";
var answ12r = "";
var answ13r = "";
var answ14r = "";


var answ15r = "";
var answ16r = "";
var answ17r = "";
var answ18r = "";
var answ19r = "";
var answ20r = "";


var slide1 = "";
//slide =  "./Physics/12/Unit2/Slide2.jpg"
//alert(slide)



var wordIndex = 0;
var global_words = [];

var utterance = new SpeechSynthesisUtterance();
var wordIndex = 0;
var global_words = [];
//utterance.lang = 'en-US';
utterance.rate = 0.8;
utterance.pitch = 1;
utterance.volume = 1;
utterance.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray = [];
var text = "";



var wordIndex1 = 0;
var global_words1 = [];

var utterance1 = new SpeechSynthesisUtterance();
var wordIndex1 = 0;
var global_words1 = [];
//utterance.lang = 'en-UK';
utterance1.rate = 0.8;
utterance1.pitch = 1;
utterance1.volume = 1;
utterance1.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray1 = [];
var text1 = "";



function newmyfun1() {


    //document.getElementById("mo").show= "false"
    window.scrollTo(0, 0)

    //document.getElementById("t1").textContent = "";
    //document.getElementById("t2").textContent = answ;

    for (var i = 0; i < 2; i++) {

        if (i == 0) {

            if (quesr.substring(0, 4) === 'Eng-') {

                utterance1.lang = 'en-US';
                utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
            }
            if (quesr.substring(0, 4) === 'Tam-') {

                utterance1.lang = 'ta-IN';//'en-UK';

                utterance1.voice = window.speechSynthesis.voice; //IOS13 fix

                //alert(utterance1.lang);

            }

            text1 = quesr.substring(4, 2000) + "." + answr.substring(4,2000);
            //alert(ques.substring(4, 2000));

            wordIndex1 = 0;
            var words1 = text1.split(".");

            global_words1 = text1;

            spokenTextArray1 = words1;
            utterance1.text = words1//text1;
            //displayfun(text1);
            // displayfun(text1)


            /*
           var voices = speechSynthesis.getVoices();
         
          
           for (var i = 0; i < voices.length; i++) {
               //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
              // document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
               if (voices[i].lang == utterance1.lang ) {
                   utterance1.voice = voices[i]; // Note: some voices don't support altering params
                   utterance1.voiceURI = voices[i].voiceURI;
                  // alert(voices[i].lang);
                    break;
               }
           }
           */
            speechSynthesis.speak(utterance1);
        }

        /*
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-UK';
            }
            if (answ.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = answ.substring(4, 2000); //+ "\n" + answ;

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);


        }

     */

    }
}
var displaystr = "";

function displayfun(displaystr) {
    //var word1 = getWordAt("")
    // alert(displaystr);

    typestring = displaystr;


    //document.getElementById("t2").textContent = displaystr;

    //document.getElementById("he1").textContent = typestring;
    //document.getElementById("t2").focus();






    wordIndex1++;


}



utterance1.onboundary = function (event) {



    var word1 = getWordAt(quesr.substring(4, 2000), event.charIndex);
    //alert(word);
    //displayfun(word1)
    //document.getElementById("t0").textContent = word1;
    //document.getElementById("t0").focus();






    wordIndex1++;

};



utterance1.onend = function () {

    //document.getElementById("t0").textContent = ques.substring(4, 2000);
    //document.getElementById("t0").focus();

   // newmyfun();

};

window.speechSynthesis.onvoiceschanged = () => {
    setVoice();
}

function setVoice() {
    //alert('ganesh');
    // utterance1.lang = 'ta-IN';
    //utterance.lang = 'ta-IN';
}


function newmyfun() {
    // document.getElementById("t1").textContent = "";
    //document.getElementById("t2").textContent = answ.substring(4, 20000);
    //alert(answ.substring(0, 4))

    for (var i = 0; i < 2; i++) {
        /*      
          if (i == 0) {
              
              if (ques.substring(0, 4) === 'Eng-') {
                
                  utterance.lang = 'en-UK';
              }
              if (ques.substring(0, 4) === 'Tam-') {
                
                  utterance.lang = 'ta-IN'//'en-UK';
  
              }
  
              text = ques.substring(4, 2000); //+ "\n" + answ;
              alert(ques.substring(4, 2000));
  
              wordIndex = 0;
              var words = text.split(" ");
  
              global_words = words;
  
              spokenTextArray = words;
              utterance.text = text;
  
              speechSynthesis.speak(utterance);
          }
          */

        if (i == 1) {
            if (answr.substring(0, 4) === 'Eng-') {
               
                utterance.lang = 'en-US';
                utterance.voice = window.speechSynthesis.voice; //IOS13 fix
            }
            if (answr.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';
                //setVoice();
                // utterance.voice = window.speechSynthesis.voice; //IOS13 fix
                // alert(utterance.lang);


            }

            text = answr.substring(4, 2000); //+ "\n" + answ;
            //alert(text);
            wordIndex = 0;
            var words = text.split(".");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text//text;
            /*
            var voices = speechSynthesis.getVoices();
            

            for (var i = 0; i < voices.length; i++) {
               // alert(voices[i].voiceURI);
               
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
                //document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang) {
                    utterance.voice = voices[i]; // Note: some voices don't support altering params
                    utterance.voiceURI = voices[i].voiceURI;
                    //alert(voices[i].voiceURI);
                     break;
                }
            }
            */

            speechSynthesis.speak(utterance);


        }



    }
}




utterance.onboundary = function (event) {



    //var word = getWordAt(answ.substring(4, 2000), event.charIndex);
    //alert(word);

    //document.getElementById("t2").textContent = word;
    //displayfun(word)
    // document.getElementById("Card1").textContent = word;

   // wordIndex++;
};



utterance.onend = function () {

    //document.getElementById("t1").textContent = answ.substring(4, 2000);
    //newmyfun1();
};

// Get the word of a string given the string and the index
function getWordAt(str, pos) {
    // Perform type conversions.
    str = String(str);
    pos = Number(pos) >>> 0;

    // Search for the word's beginning and end.
    var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
        return str.slice(left);
    }
    // Return the word, using the located bounds to extract it from the string.
    return str.slice(left, right + pos);
}


function mycancel() {

    window.speechSynthesis.cancel();
    wordIndex = 0;

}




const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Questions and answers
        </span>
);

const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    sizePerPageList: [{ text: '1', value: 10 }, { text: '10', value: 10 }, { text: 'All', value: qadata.length }] // A numeric array is also available. the purpose of above example is custom the text
};

//const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>12th Standard சிறப்புத் தமிழ்</h3>;

//<BootstrapTable     keyField='id'     data={qadata}     columns={columns}    />

//  <SearchBar {...props.searchProps} />
//<Form.Label className="text-primary" >[வகுப்பு பலகை] </Form.Label>
//caption = {< CaptionElement caption - side='top' />}

//<Form.Control as="textarea" name="t0" id="t0" controlId="t0" disabled readonly rows={2} className="text-white bg-dark" />

//<Form.Control as="textarea" name="t1" id="t1" controlId="t1" disabled readonly rows={12} className="text-white bg-dark" />
function BasicExample() {




    const selectRow = {
        mode: 'radio',
        // hideSelectColumn: false,
        clickToSelect: true,
        //clickToExpand: true,
        // clickToEdit: true,
        color: '#def3ff',
        bgColor: 'lightblue',

        classes: (row, rowIndex) => { return row, rowIndex },
        onSelect: (rows, isSelect, rowIndex, e) => {
            if (isSelect == true) {
                setShow1(false)
               
                window.speechSynthesis.cancel();
                ques = "";
                answ = "";

                answ1 = "";
                answ2 = "";
                answ3 = "";
                answ4 = "";
                answ5 = "";


                answ6 = "";
                answ7 = "";
                answ8 = "";
                answ9 = "";
                answ10 = "";


                answ11 = "";
                answ12 = "";
                answ13 = "";
                answ14 = "";
                answ15 = "";

                answ16 = "";
                answ17 = "";
                answ18 = "";
                answ19 = "";
                answ20 = "";
                quesr = "";


                slide1 = "";


                answr = "";
                answ1r = "";
                answ2r = "";
                answ3r = "";
                answ4r = "";
                answ5r = "";


                answ6r = "";
                answ7r = "";
                answ8r = "";
                answ9r = "";
                answ10r = "";

                answ11r = "";
                answ12r = "";
                answ13r = "";
                answ14r = "";
                answ15r = "";
                answ16r = "";

                answ17r = "";
                answ18r = "";
                answ19r = "";
                answ20r = "";


                window.scrollTo(0, 0)
                ques = rows.question;

                answ1 = rows.answer1

                answ2 = rows.answer2

                answ3 = rows.answer3

                answ4 = rows.answer4

                answ5 = rows.answer5



                answ6 = rows.answer6

                answ7 = rows.answer7

                answ8 = rows.answer8

                answ9 = rows.answer9

                answ10 = rows.answer10




                answ11 = rows.answer11

                answ12 = rows.answer12

                answ13 = rows.answer13

                answ14 = rows.answer14

                answ15 = rows.answer15




                answ16 = rows.answer16

                answ17 = rows.answer17

                answ18 = rows.answer18

                answ19 = rows.answer19

                answ20 = rows.answer20


                answ1r = rows.answer1r

                answ2r = rows.answer2r

                answ3r = rows.answer3r

                answ4r = rows.answer4r

                answ5r = rows.answer5r


                answ6r = rows.answer6r

                answ7r = rows.answer7r

                answ8r = rows.answer8r

                answ9r = rows.answer8r

                answ10r = rows.answer10r


                answ11r = rows.answer11r

                answ12r = rows.answer12r

                answ13r = rows.answer13r

                answ14r = rows.answer14r

                answ15r = rows.answer15r


                answ16r = rows.answer16r

                answ17r = rows.answer17r

                answ18r = rows.answer18r

                answ19r = rows.answer19r

                answ20r = rows.answer20r

                quesr = rows.questionr;


                if (answ1.length > 0)
                    {
                    setStatus1(true)
                }

                if (answ2.length > 0) {
                    setStatus2(true)
                }


                if (answ3.length > 0) {
                    setStatus3(true)
                }


                if (answ4.length > 0) {
                    setStatus4(true)
                }


                if (answ5.length > 0) {
                    setStatus5(true)
                }


                if (answ6.length > 0) {
                    setStatus6(true)
                }


                if (answ7.length > 0) {
                    setStatus7(true)
                }

                if (answ8.length > 0) {
                    setStatus8(true)
                }


                if (answ9.length > 0) {
                    setStatus9(true)
                }


                if (answ10.length > 0) {
                    setStatus10(true)
                }


                if (answ11.length > 0) {
                    setStatus11(true)
                }


                if (answ12.length > 0) {
                    setStatus12(true)
                }

                if (answ13.length > 0) {
                    setStatus13(true)
                }

                if (answ14.length > 0) {
                    setStatus14(true)
                }


                if (answ15.length > 0) {
                    setStatus15(true)
                }

                if (answ16.length > 0) {
                    setStatus16(true)
                }


                if (answ17.length > 0) {
                    setStatus17(true)
                }


                if (answ18.length > 0) {
                    setStatus18(true)
                }


                if (answ19.length > 0) {
                    setStatus19(true)
                }


                if (answ20.length > 0) {
                    setStatus20(true)
                }


                




                // './Physics/12/Unit2/Slide2.jpg'

                slide1 = rows.slide

                

                //alert(slide1);
                //logo1 = require("'"+rows.slide+"'");
                //logo1 = require(rows.slide)

                // logo1 = require('./Physics/12/Unit2/' + slide1);  -- working script
                //logo1 = slide1;
                logo1 = require('./' + slide1);

                answ = rows.answer1 + " " + rows.answer2 + " " + rows.answer3 + " " + rows.answer4 + " " + rows.answer5 + rows.answer6 + " " + rows.answer7 + " " + rows.answer8 + " " + rows.answer9 + " " + rows.answer10 + rows.answer11 + " " + rows.answer12 + " " + rows.answer13 + " " + rows.answer14 + " " + rows.answer15 + rows.answer16 + " " + rows.answer17 + " " + rows.answer18 + " " + rows.answer19 + " " + rows.answer20;

                answr = rows.answer1r + " " + rows.answer2r + " " + rows.answer3r + " " + rows.answer4r + " " + rows.answer5r + " " + rows.answer6r + " " + rows.answer7r + " " + rows.answer8r + " " + rows.answer9r + " " + rows.answer10r + " " + rows.answer11r + " " + rows.answer12r + " " + rows.answer13r + " " + rows.answer14r + " " + rows.answer15r + " "+ rows.answer16r + " " + rows.answer17r + " " + rows.answer18r + " " + rows.answer19r + " " + rows.answer20r;

                handleShow()
                // document.getElementById("t0").value = ques.substring(4, 2000);
                //document.getElementById("t1").textContent = answ.substring(4, 2000);
                //document.getElementById("t0").textContent = ques.substring(4, 2000);
                //document.getElementById("Card1").textContent = document.getElementById("t1").value = answ.substring(4, 2000);

               
                // var text2 = document.getElementById("t1").value;
                //alert(answ);
                // document.getElementById("t1").value = answ;
            }
        }

    };

    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    const [show1, setShow1] = useState(false);

    const [example, setExample] = useState(false);

    const [anss1, setStatus1] = useState(false);
    const [anss2, setStatus2] = useState(false);
    const [anss3, setStatus3] = useState(false);
    const [anss4, setStatus4] = useState(false);
    const [anss5, setStatus5] = useState(false);
    const [anss6, setStatus6] = useState(false);
    const [anss7, setStatus7] = useState(false);
    const [anss8, setStatus8] = useState(false);
    const [anss9, setStatus9] = useState(false);
    const [anss10, setStatus10] = useState(false);
    const [anss11, setStatus11] = useState(false);
    const [anss12, setStatus12] = useState(false);
    const [anss13, setStatus13] = useState(false);
    const [anss14, setStatus14] = useState(false);
    const [anss15, setStatus15] = useState(false);
    const [anss16, setStatus16] = useState(false);
    const [anss17, setStatus17] = useState(false);
    const [anss18, setStatus18] = useState(false);
    const [anss19, setStatus19] = useState(false);
    const [anss20, setStatus20] = useState(false);

    const handleClose = () => {
        mycancel();
        setShow(false);
    }



    const handleClose1 = () => {
     
        setShow1(false);
    }

    function handleShow(breakpoint) {

        if (slide1 !== 'dummy.png') {

            setExample(true)

        }
        if (slide1 === 'dummy.png') {

            setExample(false)

        }

        setFullscreen(breakpoint);
        setShow(true);
        newmyfun1()
        

    }


   function onCopyHandler(e) {
        e.preventDefault();
        e.stopPropagation();
   }


    function handleShow1(breakpoint) {

        if (slide1 !== 'dummy.png') {
         
            setShow1(true)
            
        }
        if (slide1 === 'dummy.png') {
         
            setShow1(false)
            
        }
        
      
        
    }



    const handleDone = () => {
        //console.log(`Done after 5 loops!`)
    }
    const config = {
        loader: { load: ["input/asciimath"] },
        asciimath: {
            displaystyle: true,
            delimiters: [
                ["$", "$"],
                ["`", "`"]
            ]
        }
    };



    return (

        <>
            <Typewriter
                words={['எங்கள் விலங்கியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our zoology Class Rooms', 'எங்கள் விலங்கியல் அறைகளுக்கு வரவேற்கிறோம்/Welcome to our zoology Class Rooms', 'எங்கள் விலங்கியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our zoology Class Rooms', 'எங்கள் விலங்கியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our zoology Class Rooms', 'எங்கள் விலங்கியல் வகுப்பு அறைகளுக்கு வரவேற்கிறோம்/Welcome to our zoology Class Rooms']}
                loop={5}
                cursor
                cursorStyle='🔊..'
                typeSpeed={70}
                deleteSpeed={0}
                delaySpeed={1500}


            />
            <div onCopy={onCopyHandler}>
                          
               
                
                 
                <br />
                <BootstrapTable bootstrap4 responsive="sm" responsive="sm" size="sm" keyField='id' data={qadata} columns={columns} pagination={paginationFactory(options)} selectRow={selectRow} onCopy={onCopyHandler}  />


                <Modal id="mo"
                    show={show}
                    backdrop="static"
                    onHide={handleClose}
                    keyboard={false}
                    fullscreen="true"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"


                >

                    <Modal.Header closeButton>
                        <MathJaxContext config={config}>
                            <MathJax><strong style={{ color: 'green' }}> {ques.substring(4, 2000)} </strong> </MathJax>
                        </MathJaxContext>
                    </Modal.Header>
                    <Modal.Body>
                        <Card >
                        
                          
                            <Card.Body>
                                
                                <Card.Text >
                                    <strong style={{ color: 'blue' }}>
                                        <Typewriter
                                            words={[ques.substring(4, 2000),answ1.substring(4, 2000), answ2, answ3, answ4, answ5, answ6, answ7, answ8, answ9, answ10, answ11, answ12, answ13, answ14, answ15, answ16, answ17, answ18, answ19, answ20]}
                                            loop={5}
                                            cursor
                                            cursorStyle='🔊..'
                                            typeSpeed={70}
                                            deleteSpeed={0}
                                            delaySpeed={0}
                                            onLoopDone={handleDone}

                                        />
                                    </strong>

                                </Card.Text>
                            </Card.Body>
                        </Card>



                    </Modal.Body>
                    <Modal.Footer>
                       
                        <div class="w3-container w3-blue">
                            <Form>
                                <Form.Group className="mb-3" >

                                    <MathJaxContext config={config}>

                                        {anss1 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ1.substring(4, 2000)}</MathJax></strong>:null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss2 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ2}</MathJax></strong> : null
                                        }   
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss3 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ3}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss4 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ4}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>


                                    <MathJaxContext config={config}>
                                        {anss5 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ5}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss6 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ6}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss7 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ7}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss8 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ8}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss9 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ9}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss10 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ10}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss11 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ11}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss12 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ12}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss13 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ13}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss14 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ14}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss15 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ15}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss16 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ16}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss17 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ17}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss18 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ18}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss19 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ19}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    <MathJaxContext config={config}>
                                        {anss20 ?
                                            <strong style={{ color: 'blue', fontSize: '18px' }}><MathJax>{answ20}</MathJax></strong> : null
                                        }
                                    </MathJaxContext>

                                    {example ?
                                    <Button variant="primary" onClick={handleShow1}>
                                        Show example
                                    </Button> : null
                                    }  


                                    <Modal show={show1} onHide={handleClose1} >
                                        <Modal.Header closeButton>
                                            <Modal.Title>additional information</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body></Modal.Body>

                                       
                                            <img
                                                className="d-block w-50 h-50"
                                                src={logo1}
                                                alt="Home"
                                                style={{ visibility: img_visible }}
                                            /> 
                                    </Modal>

                                   
                           
    
                


                                </Form.Group>
                            </Form>
                        </div>
                 
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );

}

export default BasicExample;