import BootstrapTable from 'react-bootstrap-table-next';
//import Card from 'react-bootstrap/Card';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import qadata from "./TamilPoem.json";
import React, { Component, useState } from "react";
import { Form, Button } from "react-bootstrap";
import logo from './Answer_t.png'
import { Row, Col, Container } from "reactstrap";

import TypeWriter from "./typewriter"

import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

const { SearchBar } = Search;


const columns = [
    {
        dataField: 'class',
        text: 'Class Name',
        hidden: true

    },
    {
        dataField: 'Subject',
        text: 'Subject Name',
        hidden: true


    }, {
        dataField: 'chapter',
        text: 'செய்யுள்',
       hidden: true

    },
    {
        dataField: 'question',
        text: 'கேள்வி',
        formatter: priceFormatter,
       

    },
    {
        dataField: 'answer',
        text: 'Answer',
        formatter: priceFormatter,
       // hidden: true,
        editor: {
            type: Type.TEXTAREA
        }
         
    },
	   {
        dataField: 'ansread',
        text: 'ansread',
        hidden: true
         
    },
    {
        dataField: 'anslines',
        text: 'anslines',
        hidden: true

    }
	
    /*
    {
        dataField: 'Read',
        text: '',
        formatter: (cellContent, row) => (
            <div className="Read     ">
                <Button
                    className="btnFormSend"
                    variant="success"
                    onClick={() => newmyfun1()}>
                    🔊
                         </Button>

                <Button
                    className="btnFormSend"
                    variant="danger"
                    onClick={() => mycancel()}>
                    ⏹
                        </Button>
            </div>
        )
    },

  
  */
];


/*

const expandRow = {

    renderer: row => (
        <div>
            <img
                className="d-block w-10 h-10"
                src={logo}
                alt="Home"
            />
            <Form.Control as="textarea" disabled readonly rows={18} controlId={"tq" + row.id} className="text-white bg-dark" value={row.answer.substring(4, 2000)} />
            
            <Button
                className="btnFormSend"
                variant="success"
                onClick={() => newmyfun1()}>
                🔊
                         </Button>

            <Button
                className="btnFormSend"
                variant="danger"
                onClick={() => mycancel()}>
                ⏹
                        </Button>
        
         </div>
    ),
    showExpandColumn: true
};

*/


function priceFormatter(cell, row) {
    return (
     
        <span>
            <strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>
        </span>
   
    );
   
}





var ques = "";
var answ = "";



var wordIndex = 0;
var global_words = [];

var utterance = new SpeechSynthesisUtterance();
var wordIndex = 0;
var global_words = [];
//utterance.lang = 'en-US';
utterance.rate = 0.8;
utterance.pitch = 1;
utterance.volume = 1;
utterance.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray = [];
var text = "";



var wordIndex1 = 0;
var global_words1 = [];

var utterance1 = new SpeechSynthesisUtterance();
var wordIndex1 = 0;
var global_words1 = [];
//utterance.lang = 'en-UK';
utterance1.rate = 0.8;
utterance1.pitch = 1;
utterance1.volume = 1;
utterance1.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray1 = [];
var text1 = "";



function newmyfun1() {
    document.getElementById("t0").value = "";
    document.getElementById("t1").value = "";
    //document.getElementById("t1").value = answ;

    for (var i = 0; i < 2; i++) {

        if (i == 0) {

            if (ques.substring(0, 4) === 'Eng-') {

                utterance1.lang = 'en-US';
                utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
            }
            if (ques.substring(0, 4) === 'Tam-') {

                utterance1.lang = 'ta-IN';//'en-UK';
                
                utterance1.voice = window.speechSynthesis.voice; //IOS13 fix

                //alert(utterance1.lang);

            }

            text1 = ques.substring(4, 2000); //+ "\n" + answ;
            //alert(ques.substring(4, 2000));

            wordIndex1 = 0;
            var words1 = text1.split(" ");

            global_words1 = words1;

            spokenTextArray1 = words1;
            utterance1.text = text1;

             /*
            var voices = speechSynthesis.getVoices();
          
           
            for (var i = 0; i < voices.length; i++) {
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
               // document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang ) {
                    utterance1.voice = voices[i]; // Note: some voices don't support altering params
                    utterance1.voiceURI = voices[i].voiceURI;
                   // alert(voices[i].lang);
                     break;
                }
            }
            */
            speechSynthesis.speak(utterance1);
        }

        /*
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-UK';
            }
            if (answ.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = answ.substring(4, 2000); //+ "\n" + answ;

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);


        }

     */

    }
}

function displayfun(displaystr) {


}


utterance1.onboundary = function (event) {


    document.getElementById("t0").value = ques.substring(4, 2000);
    document.getElementById("t0").style.color = "blue"
    var word1 = getWordAt(ques.substring(4,2000), event.charIndex);
    //alert(word);

    document.getElementById("t0").value = word1;
  





    wordIndex1++;
};



utterance1.onend = function () {

    document.getElementById("t0").value = ques.substring(4,2000);
    newmyfun();

};

window.speechSynthesis.onvoiceschanged = () => {
    setVoice();
}

function setVoice() 
{
    //alert('ganesh');
   // utterance1.lang = 'ta-IN';
    //utterance.lang = 'ta-IN';
}   


function newmyfun() {
    window.scrollTo(0, 0)
    document.getElementById("t1").value = "";
    //document.getElementById("t1").value = answ;
   
    for (var i = 0; i < 2; i++) {
      /*      
        if (i == 0) {
            
            if (ques.substring(0, 4) === 'Eng-') {
              
                utterance.lang = 'en-UK';
            }
            if (ques.substring(0, 4) === 'Tam-') {
              
                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = ques.substring(4, 2000); //+ "\n" + answ;
            alert(ques.substring(4, 2000));

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);
        }
        */
        
        if (i == 1) {
            if (answread.substring(0, 4) === 'Eng-') {
               
                utterance.lang = 'en-US';
                utterance.voice = window.speechSynthesis.voice; //IOS13 fix
            }
            if (answ.substring(0, 4) === 'Tam-') {
                
                utterance.lang = 'ta-IN'//'en-UK';
                //setVoice();
               // utterance.voice = window.speechSynthesis.voice; //IOS13 fix
               // alert(utterance.lang);
              

            }
            
            text = answ.substring(4,2000); //+ "\n" + answ;
            //alert(text);
            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = answread;
            /*
            var voices = speechSynthesis.getVoices();
            

            for (var i = 0; i < voices.length; i++) {
               // alert(voices[i].voiceURI);
               
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
                //document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang) {
                    utterance.voice = voices[i]; // Note: some voices don't support altering params
                    utterance.voiceURI = voices[i].voiceURI;
                    //alert(voices[i].voiceURI);
                     break;
                }
            }
            */

            speechSynthesis.speak(utterance);


        }

      

    }
}




utterance.onboundary = function (event) {

    

    var word = getWordAt(answ.substring(4,2000), event.charIndex);
   //alert(word);

    document.getElementById("t1").value = word;
   // document.getElementById("Card1").textContent = word;



   
    wordIndex++;
};



utterance.onend = function () {

    document.getElementById("t1").value = answ.substring(4,2000);

};

// Get the word of a string given the string and the index
function getWordAt(str, pos) {

    str = String(str);
    pos = Number(pos) >>> 0;


    var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
 

        return str;
    }
 
    return str.slice(0, pos);
}


function mycancel() {
    window.speechSynthesis.cancel();
    wordIndex = 0;

}


var answread = " "


const pageButtonRenderer = ({
    page,
    active,
    disabled,
    title,
    onPageChange
}) => {
    const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
    };
  
        alert("ganesh");
};



const selectRow = {
    mode: 'radio',
    // hideSelectColumn: false,
    clickToSelect: true,
    clickToExpand: false,
    // clickToEdit: true,
    color: '#def3ff',
    bgColor: 'lightblue',

    classes: (row, rowIndex) => { return row, rowIndex },
    onSelect: (rows, isSelect, rowIndex, e) => {
        if (isSelect == true) {
            ques = "";
            answ = "";
            ques = rows.question;
            answ = rows.answer;
            answread = rows.ansread;
            window.scrollTo(0, 0)
            document.getElementById("t0").value = ques.substring(4,2000);
            document.getElementById("t1").value = answ.substring(4, 2000);
            document.getElementById("t2").value = answread.substring(4, 2000);
            document.getElementById("t1").rows = rows.anslines 
            //document.getElementById("Card1").textContent = document.getElementById("t1").value = answ.substring(4, 2000);

            window.speechSynthesis.cancel();
           // var text2 = document.getElementById("t1").value;
          //  alert(text2);
           // document.getElementById("t1").value = answ;
        }
    }

};

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Questions and answers
        </span>
);
//, { text: 'All', value: qadata.length }
const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    sizePerPageList: [{ text: '1', value: 1 }, { text: '2', value: 2 }]
    //pageButtonRenderer// A numeric array is also available. the purpose of above example is custom the text
};

const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>12th Standard சிறப்புத் தமிழ்</h3>;

//<BootstrapTable     keyField='id'     data={qadata}     columns={columns}     expandRow={expandRow} />

//  <SearchBar {...props.searchProps} />
//<Form.Label className="text-primary" >[வகுப்பு பலகை] </Form.Label>
//caption = {< CaptionElement caption - side='top' />}
function BasicExample() {
    return (

        <ToolkitProvider
            keyField="id"
            data={qadata}
            columns={columns}
            search={{
                searchFormatted: true
            }}
        >
            {
                props => (
                    <div>
                        
                             
                        <hr />
                     
                 
                        <div class="w3-container w3-blue">
                            <h4 style={{ textAlign: 'left', color: 'red' }}>[12th Standard சிறப்புத் தமிழ்-வகுப்பு பலகை]</h4>
                            <Button
                                className="btnFormSend"
                                variant="success"
                                onClick={() => newmyfun1()}>
                                🔊
                         </Button>

                            <Button
                                className="btnFormSend"
                                variant="danger"
                                onClick={() => mycancel()}>
                                ⏹
                        </Button>

                                <Form.Group className="mb-3" bordered="true">
                                        
                                 
                                <Form.Control as="textarea" name="t0" id="t0" controlId="t0"   rows={2} disabled="off"  className="text-white bg-dark" size="none" />
                                         
                                <Form.Control as="textarea" name="t1" id="t1" controlId="t1" disabled readonly rows={18} disabled="true"  className="text-white bg-dark" size="none"  />
                               


                                <Form.Control as="textarea" name="t2" id="t2" hidden ="true" controlId="t2" readonly rows={8} className="text-white bg-dark" />
                            
                      
                                 </Form.Group>
                                
                                </div>
                            
                        
                        <BootstrapTable bootstrap4 responsive="sm" bordered="true" hover="true" responsive="sm" size="sm" keyField='id' data={qadata} columns={columns} pagination={paginationFactory(options)} selectRow={selectRow} {...props.baseProps}   />
                     
                        
                    </div>
                )
            }
        </ToolkitProvider>
         

   
    );
}

export default BasicExample;