import 'bootstrap/dist/css/bootstrap.min.css';

import NavbarComp from './components/NavbarComp';

function App() {
  return (
      <div className="App">
          <NavbarComp />
          
 
 
      </div>
    
  );
}

export default App;
