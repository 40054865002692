import React, { Component } from 'react';
import { render } from 'react-dom'
import Iframe from 'react-iframe'

	/*
	<Iframe url="https://script.google.com/macros/s/AKfycby7Ty1Wwlil3x41UzeYV6R3JQ5CAhXKStvAr52elZy9wyNazgoqcF73XR3amyfx6xtEdw/exec"
        width="1020px"
        height="1020px"
        id=""
        className=""
        display="block"
       />
*/


function Tamil() {
 
    
    return (
        
        <div className="d-block w-2000 h-2000"> 
            <Iframe src="https://script.google.com/macros/s/AKfycbw7kf4igUMx-S6CJ0GOn4tJybWb85l6VtJlSv3Drg_Jglcjezy_E5L1ZfMPkY5ciMg6LA/exec"
            width="100%"
            height="1200x"
            id=""
            className=""
            display="block"
            allow="fullscreen"
            scrolling="yes" 
           
            
        />
            </div> 
        
    );
}

export default Tamil;


