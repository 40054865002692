import BootstrapTable from 'react-bootstrap-table-next';
//import Card from 'react-bootstrap/Card';re
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import qadata from "./Phy.json";
import React, { Component, useState } from "react";
import { Form, Button } from "react-bootstrap";
import logo from './Answer_t.png'
import { Row, Col, Container } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
//import TypeWriter from "./typewriter"
import { Typewriter } from 'react-simple-typewriter';
import { Carousel } from 'react-bootstrap';
//import Card from 'react-bootstrap/Card';
//import logos1 from './Physics/12/Unit2/Slide2.jpg'
import { MathJax, MathJaxContext } from "better-react-mathjax";


import { AiOutlineAudioMuted, AiOutlineAudio, AiOutlineFileSearch } from 'react-icons/ai';

//import logo3 from { slide }
import paginationFactory from 'react-bootstrap-table2-paginator';



var kk = "dummy.png"

var tex_visible = 'visible';

var img_visible = 'visible';

var logo1 = "";

var ques = "";
var answ = "";
var answ5r = "";
var answr = "";
answr = "Please select any question and click answer button"

//logo1 = require('./Physics/12/Unit2/'+kk);  --- Working code

logo1 = require('./VK.PNG'); 


const { SearchBar, ClearSearchButton  } = Search;

var typestring = "";

typestring = ""

var moshow = "false"
var logos = "";



const columns = [
    {
        dataField: 'class',
        text: 'Class Name',
        hidden: true

    },
    {
        dataField: 'Subject',
        text: 'Subject Name',
        hidden: true


    }, {
        dataField: 'chapter',
        text: 'Chapter',

    },
    {
        dataField: 'question',
        text: 'Question',
        formatter: priceFormatter

    },
    {
        dataField: 'answer1',
        text: 'Answer1',
        hidden: true

    },
    {
        dataField: 'answer2',
        text: 'Answer2',
        hidden: true

    },
    {
        dataField: 'answer3',
        text: 'Answer3',
        hidden: true

    },
    {
        dataField: 'answer4',
        text: 'Answer4',
        hidden: true

    },
    {
        dataField: 'answer5',
        text: 'Answer5',
        hidden: true

    },
    {
        dataField: 'answer1r',
        text: 'Answer1r',
        hidden: true

    },
    {
        dataField: 'answer2r',
        text: 'Answer2r',
        hidden: true

    },
    {
        dataField: 'answer3r',
        text: 'Answer3r',
        hidden: true

    },
    {
        dataField: 'answer4r',
        text: 'Answer4r',
        hidden: true

    },
    {
        dataField: 'answer5r',
        text: 'Answer5r',
        hidden: true

    },
    {
        dataField: 'slide',
        text: 'slide',
        hidden: true

    }
    /*
    
    {
        dataField: 'Read',
        text: '',
        formatter: (cellContent, row) => (
            <div className="Read1">
                <Button
                    className="btnFormSend"
                    variant="success"
                    onClick={() => newmyfun1()}>
                    🔊
                         </Button>

                <Button
                    className="btnFormSend"
                    variant="danger"
                    onClick={() => mycancel()}>
                    ⏹
                        </Button>

            </div>
        )
    },
    */

  ];




const expandRow = {

    renderer: row => (
        <div>
            <img
                className="d-block w-10 h-10"
                src={logo}
                alt="Home"
            />
            <p className="p-3 mb-2 bg-primary bg-gradient text-white rounded-5">{row.answer.substring(4, 2000)}</p>
            <Button
                className="btnFormSend"
                variant="success"
                onClick={() => newmyfun1()}>
                🔊
                         </Button>

            <Button
                className="btnFormSend"
                variant="danger"
                onClick={() => mycancel()}>
                ⏹
                        </Button>

        </div>
    ),
    showExpandColumn: true
};

//<strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>

function priceFormatter(cell, row) {
    return (

        <strong style={{ color: 'red' }}>{cell.substring(4, 2000)} </strong>

    );

}



function priceFormatter1(cell, row) {
    return (

        <strong style={{ color: 'blue' }}>{cell.substring(4, 2000)} </strong>

    );

}





var answ1 = "";
var answ2 = "";
var answ3 = "";
var answ4 = "";
var answ5 = "";

var answr = "";
var answ1r = "";
var answ2r = "";
var answ3r = "";
var answ4r = "";

var slide1 = "";
//slide =  "./Physics/12/Unit2/Slide2.jpg"
//alert(slide)



var wordIndex = 0;
var global_words = [];

var utterance = new SpeechSynthesisUtterance();
var wordIndex = 0;
var global_words = [];
//utterance.lang = 'en-US';
utterance.rate = 0.8;
utterance.pitch = 1;
utterance.volume = 1;
utterance.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray = [];
var text = "";



var wordIndex1 = 0;
var global_words1 = [];

var utterance1 = new SpeechSynthesisUtterance();
var wordIndex1 = 0;
var global_words1 = [];
//utterance.lang = 'en-UK';
utterance1.rate = 0.8;
utterance1.pitch = 1;
utterance1.volume = 1;
utterance1.voice = window.speechSynthesis.voice; //IOS13 fix
var spokenTextArray1 = [];
var text1 = "";



function newmyfun1() {

    
    //document.getElementById("mo").show= "false"
    window.scrollTo(0, 0)
    
    //document.getElementById("t1").textContent = "";
   //document.getElementById("t2").textContent = answ;

    for (var i = 0; i < 2; i++) {

        if (i == 0) {

            if (ques.substring(0, 4) === 'Eng-') {

                utterance1.lang = 'en-US';
                utterance1.voice = window.speechSynthesis.voice;; //IOS13 fix
            }
            if (ques.substring(0, 4) === 'Tam-') {

                utterance1.lang = 'ta-IN';//'en-UK';

                utterance1.voice = window.speechSynthesis.voice; //IOS13 fix

                //alert(utterance1.lang);

            }

            text1 = ques.substring(4, 2000); //+ "\n" + answ;
            //alert(ques.substring(4, 2000));

            wordIndex1 = 0;
            var words1 = text1.split(" ");

            global_words1 = words1;

            spokenTextArray1 = words1;
            utterance1.text = text1;
            //displayfun(text1);
            // displayfun(text1)


            /*
           var voices = speechSynthesis.getVoices();
         
          
           for (var i = 0; i < voices.length; i++) {
               //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
              // document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
               if (voices[i].lang == utterance1.lang ) {
                   utterance1.voice = voices[i]; // Note: some voices don't support altering params
                   utterance1.voiceURI = voices[i].voiceURI;
                  // alert(voices[i].lang);
                    break;
               }
           }
           */
            speechSynthesis.speak(utterance1);
        }

        /*
        if (i == 1) {
            if (answ.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-UK';
            }
            if (answ.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';

            }

            text = answ.substring(4, 2000); //+ "\n" + answ;

            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;

            speechSynthesis.speak(utterance);


        }

     */

    }
}
var displaystr = "";

function displayfun(displaystr) {
    //var word1 = getWordAt("")
    // alert(displaystr);

    typestring = displaystr;
    
    
    //document.getElementById("t2").textContent = displaystr;
    
    //document.getElementById("he1").textContent = typestring;
    //document.getElementById("t2").focus();






    wordIndex1++;


}



utterance1.onboundary = function (event) {



    var word1 = getWordAt(ques.substring(4, 2000), event.charIndex);
    //alert(word);
    //displayfun(word1)
    //document.getElementById("t0").textContent = word1;
    //document.getElementById("t0").focus();






    wordIndex1++;

};



utterance1.onend = function () {

    //document.getElementById("t0").textContent = ques.substring(4, 2000);
    //document.getElementById("t0").focus();

    newmyfun();

};

window.speechSynthesis.onvoiceschanged = () => {
    setVoice();
}

function setVoice() {
    //alert('ganesh');
    // utterance1.lang = 'ta-IN';
    //utterance.lang = 'ta-IN';
}


function newmyfun() {
    // document.getElementById("t1").textContent = "";
    //document.getElementById("t2").textContent = answ.substring(4, 20000);
    //alert(answ.substring(0, 4))

    for (var i = 0; i < 2; i++) {
        /*      
          if (i == 0) {
              
              if (ques.substring(0, 4) === 'Eng-') {
                
                  utterance.lang = 'en-UK';
              }
              if (ques.substring(0, 4) === 'Tam-') {
                
                  utterance.lang = 'ta-IN'//'en-UK';
  
              }
  
              text = ques.substring(4, 2000); //+ "\n" + answ;
              alert(ques.substring(4, 2000));
  
              wordIndex = 0;
              var words = text.split(" ");
  
              global_words = words;
  
              spokenTextArray = words;
              utterance.text = text;
  
              speechSynthesis.speak(utterance);
          }
          */

        if (i == 1) {
            if (answr.substring(0, 4) === 'Eng-') {

                utterance.lang = 'en-US';
                utterance.voice = window.speechSynthesis.voice; //IOS13 fix
            }
            if (answr.substring(0, 4) === 'Tam-') {

                utterance.lang = 'ta-IN'//'en-UK';
                //setVoice();
                // utterance.voice = window.speechSynthesis.voice; //IOS13 fix
                // alert(utterance.lang);


            }

            text = answr.substring(4, 2000); //+ "\n" + answ;
            //alert(text);
            wordIndex = 0;
            var words = text.split(" ");

            global_words = words;

            spokenTextArray = words;
            utterance.text = text;
            /*
            var voices = speechSynthesis.getVoices();
            

            for (var i = 0; i < voices.length; i++) {
               // alert(voices[i].voiceURI);
               
                //document.getElementById("t0").value = document.getElementById("t0").value + '\n' + voices[i].voiceURI;
                //document.getElementById("t1").value = document.getElementById("t1").value + '\n' + voices[i].lang;
                if (voices[i].lang == utterance1.lang) {
                    utterance.voice = voices[i]; // Note: some voices don't support altering params
                    utterance.voiceURI = voices[i].voiceURI;
                    //alert(voices[i].voiceURI);
                     break;
                }
            }
            */

            speechSynthesis.speak(utterance);


        }



    }
}




utterance.onboundary = function (event) {



    var word = getWordAt(answ.substring(4, 2000), event.charIndex);
    //alert(word);

    //document.getElementById("t2").textContent = word;
    //displayfun(word)
    // document.getElementById("Card1").textContent = word;
         
    wordIndex++;
};



utterance.onend = function () {

    //document.getElementById("t1").textContent = answ.substring(4, 2000);
   newmyfun1();
};

// Get the word of a string given the string and the index
function getWordAt(str, pos) {
    // Perform type conversions.
    str = String(str);
    pos = Number(pos) >>> 0;

    // Search for the word's beginning and end.
    var left = str.slice(0, pos + 1).search(/\S+$/),
        right = str.slice(pos).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
        return str.slice(left);
    }
    // Return the word, using the located bounds to extract it from the string.
    return str.slice(left, right + pos);
}   


function mycancel() {
    
    window.speechSynthesis.cancel();
    wordIndex = 0;

}



const selectRow = {
    mode: 'radio',
    // hideSelectColumn: false,
    clickToSelect: true,
    //clickToExpand: true,
    // clickToEdit: true,
    color: '#def3ff',
    bgColor: 'lightblue',

    classes: (row, rowIndex) => { return row, rowIndex },
    onSelect: (rows, isSelect, rowIndex, e) => {
        if (isSelect == true) {

            ques = "";
            answ = "";

            answ1 = "";
            answ2 = "";
            answ3 = "";
            answ4 = "";
            answ5 = "";
            slide1 = "";


            answr = "";
            answ1r = "";
            answ2r = "";
            answ3r = "";
            answ4r = "";
            answ5r = "";


            window.scrollTo(0, 0)
            ques = rows.question;

            answ1 = rows.answer1

            answ2 = rows.answer2

            answ3 = rows.answer3

            answ4 = rows.answer4

            answ5= rows.answer5

            answ1r = rows.answer1r

            answ2r = rows.answer2r

            answ3r = rows.answer3r

            answ4r = rows.answer4r

            answ5r = rows.answer5r

            // './Physics/12/Unit2/Slide2.jpg'

            slide1 = rows.slide

            if (slide1 !== 'dummy.png') {
                //document.getElementById('text1').style.visibility = 'visible';
                
                //tex_visible = 'hidden'
              //  img_visible = 'visible'
                //alert("1")
                // hide
                //div.style.visibility = 'hidden';
                //document.getElementById("t1").style.visibility = 'visible';
               
                //alert("ganesh")
            }

            if (slide1 === 'dummy.png') {
              
                //tex_visible = 'visible'
                //img_visible = 'hidden'
                //alert("2")
                // hide
                //div.style.visibility = 'hidden';
                //document.getElementById("t1").style.visibility = 'visible';

                //alert("ganesh")
            }
         
            //alert(slide1);
            //logo1 = require("'"+rows.slide+"'");
            //logo1 = require(rows.slide)

           // logo1 = require('./Physics/12/Unit2/' + slide1);  -- working script
            //logo1 = slide1;
            logo1 = require('./' + slide1);

            answ = rows.answer1 + " " + rows.answer2 + " " + rows.answer3 + " " + rows.answer4 + " " + rows.answer5;

            answr = rows.answer1r + " " + rows.answer2r + " " + rows.answer3r + " " + rows.answer4r + " " + rows.answer5r;

            

           
            
            
            // document.getElementById("t0").value = ques.substring(4, 2000);
            //document.getElementById("t1").textContent = answ.substring(4, 2000);
            //document.getElementById("t0").textContent = ques.substring(4, 2000);
            //document.getElementById("Card1").textContent = document.getElementById("t1").value = answ.substring(4, 2000);

            window.speechSynthesis.cancel();
            // var text2 = document.getElementById("t1").value;
              //alert(answ);
            // document.getElementById("t1").value = answ;
        }
    }

};

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Questions and answers
        </span>
);

const options = {
    paginationSize: 1,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    sizePerPageList: [{ text: '1', value: 10 }, { text: '10', value: 10 }, { text: 'All', value: qadata.length }] // A numeric array is also available. the purpose of above example is custom the text
};


//const CaptionElement = () => <h3 style={{ borderRadius: '0.25em', textAlign: 'center', color: 'purple', border: '1px solid purple', padding: '0.5em' }}>12th Standard சிறப்புத் தமிழ்</h3>;

//<BootstrapTable     keyField='id'     data={qadata}     columns={columns}    />

 //<SearchBar {...props.searchProps} />
//<Form.Label className="text-primary" >[வகுப்பு பலகை] </Form.Label>
//caption = {< CaptionElement caption - side='top' />}

//<Form.Control as="textarea" name="t0" id="t0" controlId="t0" disabled readonly rows={2} className="text-white bg-dark" />

//<Form.Control as="textarea" name="t1" id="t1" controlId="t1" disabled readonly rows={12} className="text-white bg-dark" />
function BasicExample() {
    
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen, ] = useState(true);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(false);
    const [status1, setStatus1] = useState(false);
    const [status2, setStatus2] = useState(false);




    const handleClose = () => {
        mycancel();
        setShow(false);
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        if (slide1 !== 'dummy.png') {
            setStatus(true)
            setStatus1(false)
            setShow(true)
        }
        if (slide1 === 'dummy.png') {
            setStatus(false)
            setStatus1(true)
            setShow(true)
        }
        newmyfun1()
    }

    


const handleDone = () => {
    //console.log(`Done after 5 loops!`)


    }

    const config = {
        loader: { load: ["input/asciimath"] },
        asciimath: {
            displaystyle: true,
            delimiters: [
                ["$", "$"],
                ["`", "`"]
            ]
        }
    };

    var equation = "$(a+b)^2$";

    equation = "$N $(m)^1$";

    return (
        <ToolkitProvider
            keyField="id"
            data={qadata}
            columns={columns}
            search
        >
            {
                props => (


        <>
            <h4 style={{ borderRadius: '0.25em', textAlign: 'left', color: 'red', padding: '0.5em' }}>[12 Standard- Physics]
            <AiOutlineFileSearch style={{ color: 'blue', fontSize: '36px' }} onClick={handleShow} />
            </h4>
            <div>
             <SearchBar {...props.searchProps} />  
            <BootstrapTable bootstrap4 responsive="sm" responsive="sm" size="sm" keyField='id' data={qadata} columns={columns} pagination={paginationFactory(options)} selectRow={selectRow} {...props.baseProps}   />
                            <ClearSearchButton {...props.baseProps} />
                            <Modal id="mo"
                                show={show}
                                backdrop="static"
                                onHide={handleClose}
                                keyboard={false}
                                fullscreen="true"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                size="lg"


                            >
                                


                                <Modal.Header closeButton >
                                    
                                   <h1> <strong style={{ color: 'green' }}> {ques.substring(4, 2000)} </strong></h1>  
                                    
                                </Modal.Header>
                                <Modal.Body>
                                    
                                    <div class="w-auto p-3">
                                        {status ?
                                           <img
                                            className="d-block w-100 h-100"
                                            src={logo1}
                                            alt="Home"
                                            style={{ visibility: img_visible }}
                                                />:null
                                        }  
                                        {status1 ? 
                                        <text id='t2222' style={{ visibility: tex_visible }} >

                                            

                                            <strong style={{ color: 'red', fontSize: '24px' }}> {answ1.substring(4, 2000)} </strong>
                                            <br />
                                            <strong style={{ color: 'blue', fontSize: '24px' }}> {answ2} </strong>
                                            <br />
                                            <strong style={{ color: 'red', fontSize: '24px' }}> {answ3} </strong>
                                            <br />
                                            <strong style={{ color: 'blue', fontSize: '24px' }}> {answ4} </strong>
                                            <br />
                                                {status2 ? 
                                                    <strong style={{ color: 'red', fontSize: '24px' }}> {answ5} </strong> : null
                                                }  
                                            </text> : null
                                        }  

                               
                                    
                                    </div >
                                   
                                    <MathJaxContext config={config}>
                                        <MathJax dynamic inline>
                                                <strong style={{ color: 'red', fontSize: '24px' }}> {answ5} </strong>
                                        </MathJax>
                                        </MathJaxContext> 

                                </Modal.Body>
                                <Modal.Footer>
                                    <AiOutlineAudioMuted style={{ color: 'red', fontSize: '24px' }} onClick={() => window.speechSynthesis.pause()} />
                                    <AiOutlineAudio style={{ color: 'green', fontSize: '24px' }} onClick={() => window.speechSynthesis.resume()} />
                                   
                                </Modal.Footer>


                            </Modal>

                   
     
                
            </div>
                    </>
                )
            }
        </ToolkitProvider>

    );

}

export default BasicExample;